import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    listItem,
    clickable,
    additionalSingleMovieHolder,
    gatsbyImageWrapper,
    relative,
    iconLock,
    fakeWrapperIconLock,
    playIcon,
    playerItemInfo,
    title,
    time,
    icon,
    durationTime,
    ratioClass,
    restrictedButton,
} from './course-player-list-item.module.scss';
import Play from '../../../assets/images/svg/small-play-icon.svg';
import TimeIcon from '../../../assets/images/svg/time.svg';
import IconLock from '../../../assets/images/svg/icon-lock.svg';
import { humanizedSeconds } from '../../../communication/utils';
import { setChosenMovie } from '../../../redux/current-course/actions/current-course.actions';

import RatioImage from '../../atoms/ratio-image';

const CoursePlayerListItem = ({ data, onRestrictedMovie }) => {
    const dispatch = useDispatch();
    const chosenMovieId = useSelector((state) => {
        return state.currentCourse.currentCourse.chosenMovie.id;
    });

    const canWatchMovie = !(data.restrictedFields.length > 0);
    const isActiveVideo = () => chosenMovieId === data.id;

    const changeVideo = () => {
        dispatch(setChosenMovie(data));
    };

    return (
        <div
            className={`${listItem} ${clickable}`}
            onClick={() => changeVideo()}
            onKeyDown={() => changeVideo()}
            role="button"
            tabIndex={0}
        >
            <button onClick={onRestrictedMovie} className={restrictedButton} />
            <div className={additionalSingleMovieHolder}>
                {canWatchMovie ? (
                    <RatioImage
                        src={data.coverViewUri}
                        className={gatsbyImageWrapper}
                        ratioClass={ratioClass}
                        alt="Miniatura filmu"
                    />
                ) : (
                    <div className={relative}>
                        <RatioImage
                            src={data.coverViewUri}
                            className={gatsbyImageWrapper}
                            ratioClass={ratioClass}
                            alt="Miniatura filmu"
                        />
                        <IconLock className={iconLock} />
                        <div className={fakeWrapperIconLock} />
                    </div>
                )}
                {isActiveVideo() && canWatchMovie && <Play className={playIcon} />}
            </div>
            <div className={playerItemInfo}>
                <div className={title} title={data.name}>
                    {data.name}
                </div>
                <div className={time}>
                    <TimeIcon className={icon} />
                    <span className={durationTime}>{humanizedSeconds(data.videoDuration)}</span>
                </div>
            </div>
        </div>
    );
};

export default CoursePlayerListItem;
