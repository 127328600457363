import React from 'react';
import { Link } from 'gatsby';

import { wrapper, title, emotics, subtitle } from './metamorphosis-slider.module.scss';
import MetamorphosisEmotics from '../../assets/images/svg/metamorphosis-emotics.svg';

import Title from '../atoms/title';
import SubTitle from '../atoms/subtitle';
import Emotic from '../atoms/emotic';
import Button from '../atoms/button';
import MetamorphosisCard from '../molecules/metamorphosis-card';
import Slider from '../hoc/slider';

const MetamorphosisSlider = ({ slides, className }) => {
    return (
        <div className={`${wrapper} ${className}`}>
            <Emotic emotic={MetamorphosisEmotics} className={emotics} />
            <Title className={title}>
                Nasi podopieczni, uwielbiają z nami współpracować!
                <br />
                Poznaj ich oraz ich przemiany!
            </Title>
            <SubTitle className={subtitle}>
                Naszą misją i celem jest pomaganie w osiągnięciu wymarzonej sylwetki.
                <br />
                Nie robimy jednak tego w standardowy sposób.
            </SubTitle>
            <Slider>
                {slides.map((slide, index) => {
                    return (
                        <MetamorphosisCard cardData={slide} key={`metamorphosis-slide-${index}`} />
                    );
                })}
            </Slider>
            <Link to="/metamorfozy/">
                <Button color="yellow">Poznaj wszystkie historie</Button>
            </Link>
        </div>
    );
};

export default MetamorphosisSlider;
