import {
    selectDiscountCode,
    selectChosenXselling,
    selectPayByPointsItems,
} from '../redux/shop/order/order.selectors';
import { groupBy, pickFields } from './data-convertion';

export const prepareOrderData = (state) => {
    const { shop } = state;

    const registerUser = shop.order.registerUser;
    const userData = shop.order.userData;

    const orderData = {};

    orderData.consents = state.shop.order.consents;

    if (shop.cart) {
        // TEMPORARY START - sending price causes bugs - chudy

        // orderData.price = shop.cart.price;
        orderData.items = shop.cart.items;

        // TEMPORARY END
    }

    orderData.discountCode = selectDiscountCode(state);

    orderData.chosenXselling = selectChosenXselling(state);

    orderData.productIdsForPointsPayment = prepareIdsForPointsPayment(state);

    orderData.registerUser = registerUser;

    orderData.userData = userData;

    return orderData;
};

function prepareIdsForPointsPayment(state) {
    const checkedItems = Object.values(selectPayByPointsItems(state))
        .filter((item) => item.checked)
        .map(pickFields(['productId', 'promotionId']));

    return Object.entries(groupBy(checkedItems, 'promotionId')).reduce(
        (acc, [promotionId, items]) => {
            return [
                ...acc,
                {
                    promotionId,
                    productsIds: items.map((item) => item.productId),
                },
            ];
        },
        []
    );
}
