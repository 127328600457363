import { businessMeaning } from '../../../global-enums/product';
import { config } from '../../../config';
import { selectLoaderByEntity } from '../../ui/ui.selectors';

const accountType = config.subscriptionMap.attributes.accountType.values;

export const selectCart = (state) => {
    return state.shop?.cart;
};

export const selectCartItems = (state) => {
    return selectCart(state)?.items || [];
};

export const selectCartItemsCount = (state) => state.shop.cart?.items?.length || 0;

export const findSubscriptionTypeInCart = (state) => {
    const subscription = selectCartItems(state)?.find(
        (item) => item.product.businessMeaning === businessMeaning.subscription
    );

    return isSubscription(subscription);
};

export const isSubscription = (item) => {
    switch (true) {
        case item?.subscriptionSp?.isPair:
            return accountType.pair.key;
        case item?.subscriptionSp?.isSingle:
            return accountType.single.key;
        default:
            return 'no-sub';
    }
};

export const selectCartIsLoading = (state) => selectLoaderByEntity(state, 'cart');
