import { types as apiTypes } from '../api/api.actions';
import { config } from '../../config';
import { getAuthorizationBearerHeader } from '../../utills/get-authorization-bearer-header';

export const USER = '[USER]';

export const types = {
    //document actions:
    SET_USER_DATA: `${USER} SET_USER_DATA`,
    SET_USER_VERIFICATION_STATUS: `${USER} SET_USER_VERIFICATION_STATUS`,
    CLEAR_USER_DATA: `${USER} CLEAR_USER_DATA`,

    //command actions:
    LOGIN_USER: `${USER} login`, //command (user triggered)
    LOGOUT_USER: `${USER} logout`, //command
    REGISTER_USER: `${USER} register`,
    CHANGE_PASSWORD: `${USER} change_password`,
    ACTIVATE_ACCOUNT: `${USER} activate_account`,
    DELETE_ACCOUNT: `${USER} delete account`,
    // GET_USER_DATA: `${USER} get_user_data`, //event (middleware)

    //api actions:
    LOGIN_USER_REQUEST: `${USER} login ${apiTypes.API_REQUEST}`,
    LOGIN_USER_SUCCESS: `${USER} login ${apiTypes.API_SUCCESS}`,
    LOGIN_USER_FAIL: `${USER} login ${apiTypes.API_FAIL}`,

    LOGOUT_USER_REQUEST: `${USER} logout ${apiTypes.API_REQUEST}`,
    LOGOUT_USER_SUCCESS: `${USER} logout ${apiTypes.API_SUCCESS}`,
    LOGOUT_USER_FAIL: `${USER} logout ${apiTypes.API_FAIL}`,

    GET_USER_DATA_REQUEST: `${USER} get_user_data ${apiTypes.API_REQUEST}`,
    GET_USER_DATA_SUCCESS: `${USER} get_user_data ${apiTypes.API_SUCCESS}`,
    GET_USER_DATA_FAIL: `${USER} get_user_data ${apiTypes.API_FAIL}`,

    REGISTER_USER_REQUEST: `${USER} register ${apiTypes.API_REQUEST}`,
    REGISTER_USER_SUCCESS: `${USER} register ${apiTypes.API_SUCCESS}`,
    REGISTER_USER_FAIL: `${USER} register ${apiTypes.API_FAIL}`,

    CHANGE_PASSWORD_REQUEST: `${USER} change_password ${apiTypes.API_REQUEST}`,
    CHANGE_PASSWORD_SUCCESS: `${USER} change_password ${apiTypes.API_SUCCESS}`,
    CHANGE_PASSWORD_FAIL: `${USER} change_password ${apiTypes.API_FAIL}`,

    ACTIVATE_ACCOUNT_REQUEST: `${USER} activate_account ${apiTypes.API_REQUEST}`,
    ACTIVATE_ACCOUNT_SUCCESS: `${USER} activate_account ${apiTypes.API_SUCCESS}`,
    ACTIVATE_ACCOUNT_FAIL: `${USER} activate_account ${apiTypes.API_FAIL}`,

    DELETE_ACCOUNT_REQUEST: `${USER} delete account ${apiTypes.API_REQUEST}`,
    DELETE_ACCOUNT_SUCCESS: `${USER} delete account ${apiTypes.API_SUCCESS}`,
    DELETE_ACCOUNT_FAIL: `${USER} delete account ${apiTypes.API_FAIL}`,
};

export const setUserData = (payload) => ({
    type: types.SET_USER_DATA,
    payload,
});
export const clearUserData = () => ({
    type: types.CLEAR_USER_DATA,
});

export const setUserVerificationStatus = (isUserVerificationFinished) => ({
    type: types.SET_USER_VERIFICATION_STATUS,
    isUserVerificationFinished,
});

export const loginUser = (payload) => ({
    type: types.LOGIN_USER,
    payload,
});

export const logoutUser = () => ({
    type: types.LOGOUT_USER,
});

export const registerUser = (payload, formikBag) => ({
    type: types.REGISTER_USER,
    payload,
    meta: {
        formikBag,
    },
});
export const activateUser = (payload, meta) => ({
    type: types.ACTIVATE_ACCOUNT,
    payload,
    meta,
});

export const changePassword = ({ formValues, formikBag }) => ({
    type: types.CHANGE_PASSWORD,
    payload: formValues,
    meta: {
        formikBag,
    },
});

export const postLoginUser = ({ formValues, formikBag }) => ({
    types: [types.LOGIN_USER_REQUEST, types.LOGIN_USER_SUCCESS, types.LOGIN_USER_FAIL],
    payload: {
        request: {
            url: config.endpoints.login,
            method: 'POST',
            data: formValues,
            headers: { ...getAuthorizationBearerHeader() },
        },
    },
    meta: {
        formikBag,
    },
});

export const postLogoutUser = () => ({
    types: [types.LOGOUT_USER_REQUEST, types.LOGOUT_USER_SUCCESS, types.LOGOUT_USER_FAIL],
    payload: {
        request: {
            url: config.endpoints.logout,
            method: 'POST',
            headers: { ...getAuthorizationBearerHeader() },
        },
    },
});

export const fetchUserData = (meta) => ({
    types: [types.GET_USER_DATA_REQUEST, types.GET_USER_DATA_SUCCESS, types.GET_USER_DATA_FAIL],
    payload: {
        request: {
            url: config.endpoints.myAccount,
            method: 'GET',
            headers: { ...getAuthorizationBearerHeader() },
        },
    },
    meta: {
        ...meta,
        entity: USER,
    },
});

export const postRegisterUser = ({ payload, formikBag }) => ({
    types: [types.REGISTER_USER_REQUEST, types.REGISTER_USER_SUCCESS, types.REGISTER_USER_FAIL],
    payload: {
        request: {
            url: config.endpoints.register,
            method: 'POST',
            data: payload,
            headers: { ...getAuthorizationBearerHeader() },
        },
    },
    meta: {
        formikBag,
    },
});

export const postChangePassword = ({ payload, formikBag }) => ({
    types: [
        types.CHANGE_PASSWORD_REQUEST,
        types.CHANGE_PASSWORD_SUCCESS,
        types.CHANGE_PASSWORD_FAIL,
    ],
    payload: {
        request: {
            url: config.endpoints.changePassword,
            method: 'POST',
            data: payload,
            headers: { ...getAuthorizationBearerHeader() },
        },
    },
    meta: {
        formikBag,
    },
});

export const postActivateAccount = (payload, meta) => ({
    types: [
        types.ACTIVATE_ACCOUNT_REQUEST,
        types.ACTIVATE_ACCOUNT_SUCCESS,
        types.ACTIVATE_ACCOUNT_FAIL,
    ],
    payload: {
        request: {
            url: config.endpoints.activateAccount(payload),
            headers: { ...getAuthorizationBearerHeader() },
        },
    },
    meta: { ...meta, entity: USER },
});

export const deleteAccountAction = (formikValues, formikBag, modalId) => ({
    type: types.DELETE_ACCOUNT,
    payload: formikValues,
    meta: {
        formikBag,
        modalId,
    },
});

export const fetchDeleteAccountAction = ({ formikValues, formikBag, modalId }) => ({
    types: [types.DELETE_ACCOUNT_REQUEST, types.DELETE_ACCOUNT_SUCCESS, types.DELETE_ACCOUNT_FAIL],
    payload: {
        request: {
            url: config.endpoints.myAccountDeactivate,
            method: 'POST',
            data: formikValues,
            headers: { ...getAuthorizationBearerHeader() },
        },
    },
    meta: {
        formikBag,
        modalId,
    },
});
