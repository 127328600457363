import { setDiscounts, DISCOUNTS, requestDiscountsAll, types } from './discounts.actions';
import { config } from '../../../config';
import { arrayToObject } from '../../../utills/data-convertion';
import { getAuthorizationBearerHeader } from '../../../utills/get-authorization-bearer-header';

const discountsMiddleware = (state) => (next) => (action) => {
    const { dispatch } = state;
    const { type, payload, error } = action;

    next(action);

    switch (type) {
        case types.REQUEST_DISCOUNTS_ALL:
            dispatch({
                types: [
                    types.FETCH_DISCOUNTS_ALL,
                    types.FETCH_DISCOUNTS_ALL_SUCCESS,
                    types.FETCH_DISCOUNTS_ALL_FAIL,
                ],
                payload: {
                    request: {
                        url: config.endpoints.shop.productDiscountsAll,
                        headers: { ...getAuthorizationBearerHeader() },
                        params: {
                            page: payload?.page,
                            'per-page': 50,
                        },
                    },
                },
                meta: {
                    entity: DISCOUNTS,
                },
            });
            break;
        case types.FETCH_DISCOUNTS_ALL_SUCCESS:
            const items = arrayToObject(payload.data.items, 'productId');
            const pagination = payload.data.pagination;

            dispatch(setDiscounts(items));

            if (pagination.currentPage < pagination.pageCount) {
                dispatch(requestDiscountsAll({ page: pagination.currentPage + 1 }));
            }
            break;
        case types.FETCH_DISCOUNTS_ALL_FAIL:
        case types.FETCH_DISCOUNT_FAIL:
            console.error(error?.message);
            break;
        case types.REQUEST_DISCOUNT:
            dispatch({
                types: [
                    types.FETCH_DISCOUNT,
                    types.FETCH_DISCOUNT_SUCCESS,
                    types.FETCH_DISCOUNT_FAIL,
                ],
                payload: {
                    request: {
                        url: config.endpoints.shop.productDiscount(payload),
                        headers: { ...getAuthorizationBearerHeader() },
                    },
                },
                meta: {
                    entity: DISCOUNTS,
                },
            });
            break;
        case types.FETCH_DISCOUNT_SUCCESS:
            const item = arrayToObject([payload.data], 'productId');
            dispatch(setDiscounts(item));
            break;
        //no default
    }
};

export default discountsMiddleware;
