import React from 'react';

import { wrapper } from './payment-preview.module.scss';

const PaymentPreview = ({ text, Icon, iconClassName = '', className = '' }) => {
    return (
        <div className={`${wrapper} ${className}`}>
            <div>{text}</div>

            {Icon && (
                <div className={iconClassName}>
                    <Icon />
                </div>
            )}
        </div>
    );
};

export default PaymentPreview;
