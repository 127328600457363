// extracted by mini-css-extract-plugin
export var form = "credit-card-data-module--form--IBTi3";
export var iconBox = "credit-card-data-module--icon-box--8bV0z";
export var info = "credit-card-data-module--info--q2nj1";
export var infoBox = "credit-card-data-module--info-box--uTTgI";
export var infoCard = "credit-card-data-module--info-card--I0RLw";
export var infoCardContent = "credit-card-data-module--info-card-content--ufRr4";
export var infoCardText = "credit-card-data-module--info-card-text--J4iXX";
export var infoCardTitle = "credit-card-data-module--info-card-title--SIhrs";
export var infoHeader = "credit-card-data-module--info-header--o2vNp";
export var loaderBox = "credit-card-data-module--loader-box--T18RP";
export var logo = "credit-card-data-module--logo--M1QoO";
export var payuImg = "credit-card-data-module--payu-img--VAGaR";
export var statusItem = "credit-card-data-module--status-item--DXX6z";
export var statusList = "credit-card-data-module--status-list--Y++1r";
export var title = "credit-card-data-module--title--waUJA";
export var wrapper = "credit-card-data-module--wrapper--uzpSv";