import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { graphql, Script, useStaticQuery } from 'gatsby';
import { Helmet } from 'react-helmet';

import { config } from '../config';
import { mainGrid } from '../style/grid.module.scss';
import {
    wrapper,
    main,
    alertBox,
    paddingEqualToHeader,
    paddingEqualToHeaderOnPhone,
    transparent,
    metamorphosis,
} from './main.module.scss';
import { removeMultipleModalsAction } from '../redux/actions/actions-modals';
import { entity } from '../rbac/permissions';
import { deleteAlert } from '../redux/alerts/alerts.actions';
import useSSE from '../hooks/use-sse';

import Footer from '../components/organisms/footer';
import JoinBox from '../components/organisms/join-box';
import Newsletter from '../components/organisms/newsletter';
import GlobalModal from '../components/hoc/modals/global-modal';
import StatusBar from '../components/atoms/status-bar';
import HeightSpace from '../components/atoms/height-space/height-space';
import UserCan from '../rbac/hoc';
import Cookies from '../components/organisms/cookies';
import TopBar from '../components/organisms/top-bar';
import PointsWidget from '../components/organisms/points-widget';
import MetamorphosisSlider from '../components/organisms/metamorphosis-slider';
import { getUserTokenData } from '../utills/get-user-token-data';
import { getIsTokenExpired } from '../utills/get-is-token-expired';
import { selectRbac } from '../redux/rbac/rbac.selectors';
import userHaveRightsTo from '../rbac/utils';

const LIVE_CHAT_LICENSE = process.env.LIVE_CHAT_LICENSE;

const Main = ({
    children,
    isPaddingEqualToHeader = false,
    isPaddingEqualToHeaderOnPhone = false,
    className,
    transparentHeader,
    isShopCart = false,
    hasJoinBox = true,
    hasMetamorphosisSlider = false,
    hasNewsletterForm = true,
    customTopBarColor = '',
}) => {
    const data = useStaticQuery(query);
    const dispatch = useDispatch();
    const { newsletterSignUp, modals, alerts } = useSelector((state) => ({
        newsletterSignUp: state.user.userData?.isNewsletterSubscriber,
        modals: state.modals,
        alerts: state.alerts,
    }));
    const rbac = useSelector(selectRbac);
    const isSubscriber = userHaveRightsTo(entity.SUBSCRIBER, rbac || []);
    const tokenData = getUserTokenData();
    const isTokenExpired = getIsTokenExpired(tokenData?.expireAt);

    const [showNewsletterForm, setShowNewsletterForm] = useState(true);

    useSSE();

    let windowLocationPathname;
    if (typeof window !== 'undefined') {
        windowLocationPathname = window.location.pathname;
    }

    useEffect(() => {
        if (modals.length) {
            const modalIdsToRemove = modals
                .filter((modal) => {
                    return modal.path !== window.location.pathname && !modal.data.forceAction;
                })
                .map((modal) => modal.id);
            if (modalIdsToRemove.length) dispatch(removeMultipleModalsAction(modalIdsToRemove));
        }
    }, [windowLocationPathname, dispatch, modals]);

    useEffect(() => {
        switch (true) {
            case !hasNewsletterForm:
            case newsletterSignUp:
                setShowNewsletterForm(false);
                break;
            default:
                setShowNewsletterForm(true);
        }
    }, [hasNewsletterForm, newsletterSignUp]);

    useEffect(() => {
        if (isTokenExpired) {
            localStorage.removeItem('tokenData');
            window.location.reload();
        }
    }, [isTokenExpired]);

    useEffect(() => {
        const body = document.querySelector('body');
        if (isSubscriber) {
            body.classList.add('js-live-chat-hidden');
        } else {
            body.classList.remove('js-live-chat-hidden');
        }
    }, [isSubscriber]);

    const cssClass = `
        ${wrapper} 
        ${transparentHeader ? transparent : ''}
        ${isPaddingEqualToHeader ? paddingEqualToHeader : ''}
        ${isPaddingEqualToHeaderOnPhone ? paddingEqualToHeaderOnPhone : ''}
        `;

    return (
        <>
            <Helmet>
                <meta name="google-site-verification" content={config.googleSiteVerification} />
            </Helmet>
            <Script>
                {`window.__lc = window.__lc || {};window.__lc.license = ${LIVE_CHAT_LICENSE};window.__lc.integration_name = "manual_restart_trial";window.__lc.product_name = "livechat";;(function(n,t,c){function i(n){return e._h?e._h.apply(null,n):e._q.push(n)}var e={_q:[],_h:null,_v:"2.0",on:function(){i(["on",c.call(arguments)])},once:function(){i(["once",c.call(arguments)])},off:function(){i(["off",c.call(arguments)])},get:function(){if(!e._h)throw new Error("[LiveChatWidget] You can't use getters before load.");return i(["get",c.call(arguments)])},call:function(){i(["call",c.call(arguments)])},init:function(){var n=t.createElement("script");n.async=!0,n.type="text/javascript",n.src="https://cdn.livechatinc.com/tracking.js",t.head.appendChild(n)}};!n.__lc.asyncInit&&e.init(),n.LiveChatWidget=n.LiveChatWidget||e}(window,document,[].slice))`}
            </Script>
            <div className={cssClass}>
                <TopBar
                    transparent={transparentHeader}
                    isShopCart={isShopCart}
                    customColor={customTopBarColor}
                />
                <main className={`${mainGrid} ${main} ${className}`}>{children}</main>

                {hasMetamorphosisSlider && (
                    <section className={metamorphosis}>
                        <MetamorphosisSlider
                            slides={data.allMetamorphosis.edges.map((edge) => edge.node)}
                        />
                    </section>
                )}

                {hasJoinBox && (
                    <UserCan
                        action={entity.SUBSCRIBER}
                        yes={() => <HeightSpace />}
                        no={() => <JoinBox />}
                    />
                )}

                {showNewsletterForm ? <Newsletter /> : null}

                <Footer siteMetadata={data.site.siteMetadata} />
            </div>

            <Cookies />

            {modals.map(({ id, data }) => (
                <GlobalModal data={data} id={id} key={id} />
            ))}

            <div className={alertBox}>
                {Object.entries(alerts).map(([key, item]) => {
                    return (
                        <StatusBar
                            key={key}
                            type={item.type}
                            onSubmit={() => dispatch(deleteAlert(key))}
                        >
                            {item.content}
                        </StatusBar>
                    );
                })}
            </div>

            <PointsWidget />
        </>
    );
};

const query = graphql`
    {
        site {
            ...siteFields
        }
        layoutBg: file(name: { eq: "layout-bg" }) {
            childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
            }
        }
        banner: file(relativePath: { eq: "banner.png" }) {
            childImageSharp {
                gatsbyImageData(quality: 95, layout: FULL_WIDTH, placeholder: BLURRED)
            }
        }
        allMetamorphosis(sort: { fields: createdAt, order: DESC }, limit: 5) {
            edges {
                node {
                    author
                    content
                    imageUrl
                }
            }
        }
    }
`;

Main.propTypes = {
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.instanceOf(null)]),
    className: PropTypes.string,
    customTopBarColor: PropTypes.string,
    isPaddingEqualToHeader: PropTypes.bool,
    isPaddingEqualToHeaderOnPhone: PropTypes.bool,
    transparentHeader: PropTypes.bool,
    isShopCart: PropTypes.bool,
    hasJoinBox: PropTypes.bool,
    hasNewsletterForm: PropTypes.bool,
};

Main.defaultProps = {
    children: null,
    className: '',
    customTopBarColor: '',
    isPaddingEqualToHeader: false,
    isPaddingEqualToHeaderOnPhone: false,
    transparentHeader: false,
    isShopCart: false,
    hasJoinBox: true,
    hasNewsletterForm: true,
};

export default Main;
