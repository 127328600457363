import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { useStaticQuery, graphql } from 'gatsby';
import { useDispatch, useSelector } from 'react-redux';

import {
    form,
    title,
    logo,
    wrapper,
    infoBox,
    info,
    infoCard,
    iconBox,
    infoCardTitle,
    infoCardText,
    infoCardContent,
    infoHeader,
    payuImg,
    loaderBox,
    statusList,
    statusItem,
} from './credit-card-data.module.scss';
import Logo from '../assets/images/svg/logo.svg';
import ShieldIcon from '../assets/images/svg/icon-payu-shield.svg';
import AvailabilityIcon from '../assets/images/svg/icon-payu-availability.svg';
import { config } from '../config';
import { submitPayUTokenAction, submitPayUTokenRetryAction } from '../redux/shop/payu/payu.actions';
import { selectLoaderByEntity } from '../redux/ui/ui.selectors';
import {
    selectIsCheckingPayUStatus,
    selectPayUOrderStatus,
} from '../redux/shop/payu/payu.selectors';

import SEO from '../components/seo';
import Main from '../layouts/main';
import PayUCardForm from '../components/organisms/payu-card-form';
import Loader from '../components/atoms/loader';

const payUColor = '#A6C307';
const paymentStatusMap = config.shop.order.paymentStatusMap;

const CreditCardPage = ({
    // id can be orderId or paymentId depending on whether it is first try or retry
    id,
    isRetry,
}) => {
    const { payULogo } = useStaticQuery(query);
    const dispatch = useDispatch();
    const tokenSubmitLoading = useSelector((state) => selectLoaderByEntity(state, 'payu'));
    const isChecking = useSelector(selectIsCheckingPayUStatus);
    const orderStatus = useSelector(selectPayUOrderStatus);

    const sendPayUToken = (token) => {
        if (isRetry) {
            dispatch(submitPayUTokenRetryAction(id, token));
        } else {
            dispatch(submitPayUTokenAction(id, token));
        }
    };

    return (
        <Main className={wrapper} isShopCart={true} customTopBarColor={payUColor}>
            {isChecking && (
                <div className={loaderBox}>
                    <Loader />
                    <p>{copy.checkingPayment}</p>
                    {orderStatus && (
                        <ul className={statusList}>
                            {orderStatus.payments.map((payment) => {
                                return (
                                    <li className={statusItem} key={payment.paymentId}>
                                        <p>{payment.name}</p>
                                        <p>{payment.price.grossDisplay}</p>
                                        <p>{paymentStatusMap[payment.status]?.label}</p>
                                    </li>
                                );
                            })}
                        </ul>
                    )}
                </div>
            )}
            <SEO title={copy.SEOTitle} />
            <h1 className={title}>{copy.title}</h1>
            <div className={form}>
                <PayUCardForm onTokenSuccess={sendPayUToken} isLoading={tokenSubmitLoading} />
            </div>
            <div className={info}>
                <div className={infoBox}>
                    <div className={infoHeader}>
                        <GatsbyImage
                            image={getImage(payULogo)}
                            imgStyle={{
                                objectFit: 'contain',
                                objectPosition: 'left',
                            }}
                            className={payuImg}
                            alt="Logo PayU"
                        />
                    </div>
                    <div className={infoCard}>
                        <div className={iconBox}>
                            <ShieldIcon />
                        </div>
                        <div className={infoCardContent}>
                            <h3 className={infoCardTitle}>{copy.infoSafetyTitle}</h3>
                            <p className={infoCardText}>{copy.infoSafetyText}</p>
                        </div>
                    </div>
                </div>
                <div className={infoBox}>
                    <div className={infoHeader}>
                        <Logo className={logo} />
                    </div>
                    <div className={infoCard}>
                        <div className={iconBox}>
                            <AvailabilityIcon />
                        </div>
                        <div className={infoCardContent}>
                            <h3 className={infoCardTitle}>{copy.infoAvailabilityTitle}</h3>
                            <p className={infoCardText}>{copy.infoAvailabilityText}</p>
                        </div>
                    </div>
                </div>
            </div>
        </Main>
    );
};

const query = graphql`
    {
        payULogo: file(relativePath: { eq: "payu-logo-color.png" }) {
            childImageSharp {
                gatsbyImageData(width: 200, quality: 100, layout: CONSTRAINED, placeholder: BLURRED)
            }
        }
    }
`;

const copy = {
    title: (
        <>
            Twoje bezpieczeństwo jest dla nas najważniejsze!
            <br />
            Dane karty są&nbsp;zabezpieczone przez największy polski serwis transakcyjny Payu.pl!
        </>
    ),
    SEOTitle: `Dane karty płatniczej`,
    checkingPayment: `Prosimy o chwilę cierpliwości, czekamy na potwierdzenie od PayU...`,
    infoSafetyTitle: `100% bezpieczeństwa`,
    infoSafetyText: `PayU posiada certyfikaty PCI-DSS, protokół EV\xa0SSL oraz system 3D\xa0Secure,
            własny zespół i\xa0system Anti-Fraud oraz dwa niezależne centra danych,
            co\xa0minimalizuje ryzyko awarii i\xa0gwarantuje ciągłość transakcji oraz
            bezpieczeństwo dla użytkowników.`,
    infoAvailabilityTitle: `Dostępność z\xa0każdego urządzenia`,
    infoAvailabilityText: `System transakcyjny został zaprojektowany w\xa0oparciu
            o\xa0technologię Responsive Web Design, dzięki której klient może
            łatwo i\xa0wygodnie płacić ze\xa0swojego laptopa, smartfona lub
            tabletu. Możesz dostosować wygląd ekranu do\xa0wyglądu Twojego
            sklepu, zyskując zaufanie klientów i\xa0zwiększając tym samym
            konwersję.`,
};

export default CreditCardPage;
