import { config } from '../../config';

import {
    types,
    SINGLE_SHOPPING_LIST,
    SINGLE_SHOPPING_LIST_CHANGE,
    setSingleShoppingList,
    restoreInitialState,
} from './single-shopping-list.actions';
import { formatFormikErrors } from '../../communication/utils';
import { statusIdle } from '../ui/ui.actions';
import { clearShoppingLists } from '../shopping-lists/shopping-lists.actions';
import { getAuthorizationBearerHeader } from '../../utills/get-authorization-bearer-header';

const {
    GET_SINGLE_SHOPPING_LIST,
    FETCH_SINGLE_SHOPPING_LIST_REQUEST,
    FETCH_SINGLE_SHOPPING_LIST_SUCCESS,
    FETCH_SINGLE_SHOPPING_LIST_FAIL,
    CREATE_SHOPPING_LIST,
    POST_SHOPPING_LIST_REQUEST,
    POST_SHOPPING_LIST_SUCCESS,
    POST_SHOPPING_LIST_FAIL,
    EDIT_SHOPPING_LIST,
    PUT_SHOPPING_LIST_REQUEST,
    PUT_SHOPPING_LIST_SUCCESS,
    PUT_SHOPPING_LIST_FAIL,
    DELETE_SHOPPING_LIST,
    DELETE_SHOPPING_LIST_REQUEST,
    DELETE_SHOPPING_LIST_SUCCESS,
    DELETE_SHOPPING_LIST_FAIL,
    CLEAR_SINGLE_SHOPPING_LIST,
} = types;

const { formsStatusMap } = config;

const singleShoppingListMiddleware = (state) => (next) => (action) => {
    const { dispatch, getState } = state;
    const { type, payload, meta } = action;
    next(action);

    switch (type) {
        case GET_SINGLE_SHOPPING_LIST:
            const fetchSingleShoppingList = {
                types: [
                    FETCH_SINGLE_SHOPPING_LIST_REQUEST,
                    FETCH_SINGLE_SHOPPING_LIST_SUCCESS,
                    FETCH_SINGLE_SHOPPING_LIST_FAIL,
                ],
                payload: {
                    request: {
                        url: config.endpoints.shoppingLists.single.get(
                            payload,
                            getState().profile.currentId
                        ),
                        headers: { ...getAuthorizationBearerHeader() },
                    },
                },
                meta: { entity: SINGLE_SHOPPING_LIST },
            };
            dispatch(fetchSingleShoppingList);
            break;

        case FETCH_SINGLE_SHOPPING_LIST_SUCCESS:
            dispatch(setSingleShoppingList(payload.data));
            break;

        case CREATE_SHOPPING_LIST:
            if (meta?.formikBag) {
                meta.formikBag.setStatus(formsStatusMap.loading);
            }
            const postShoppingList = {
                types: [
                    POST_SHOPPING_LIST_REQUEST,
                    POST_SHOPPING_LIST_SUCCESS,
                    POST_SHOPPING_LIST_FAIL,
                ],
                payload: {
                    request: {
                        method: 'post',
                        url: config.endpoints.shoppingLists.create,
                        data: payload,
                        headers: { ...getAuthorizationBearerHeader() },
                    },
                },
                meta: { ...meta, entity: SINGLE_SHOPPING_LIST_CHANGE },
            };
            dispatch(postShoppingList);
            break;

        case POST_SHOPPING_LIST_SUCCESS:
            const postSuccessFormikBag = meta.previousAction?.meta?.formikBag;
            if (postSuccessFormikBag) {
                postSuccessFormikBag.setStatus(formsStatusMap.success);
            }
            break;

        case POST_SHOPPING_LIST_FAIL:
            const postFailFormikBag = meta.previousAction?.meta?.formikBag;
            if (postFailFormikBag) {
                postFailFormikBag.setStatus(formsStatusMap.fail);
                postFailFormikBag.setErrors(formatFormikErrors(action.error));
            }
            break;

        case EDIT_SHOPPING_LIST:
            if (meta?.formikBag) {
                meta.formikBag.setStatus(formsStatusMap.loading);
            }

            const profileId = meta?.fromRecipe ? getState().profile.currentId : null;
            const editEndpoint = profileId
                ? config.endpoints.shoppingLists.single.editFromRecipe(payload.id, profileId)
                : config.endpoints.shoppingLists.single.edit(payload.id);

            const putShoppingList = {
                types: [
                    PUT_SHOPPING_LIST_REQUEST,
                    PUT_SHOPPING_LIST_SUCCESS,
                    PUT_SHOPPING_LIST_FAIL,
                ],
                payload: {
                    request: {
                        method: 'put',
                        url: editEndpoint,
                        data: payload.data,
                        headers: { ...getAuthorizationBearerHeader() },
                    },
                },
                meta: { ...meta, entity: SINGLE_SHOPPING_LIST_CHANGE },
            };
            dispatch(putShoppingList);
            break;

        case PUT_SHOPPING_LIST_SUCCESS:
            const putSuccessFormikBag = meta.previousAction?.meta?.formikBag;
            if (putSuccessFormikBag) {
                putSuccessFormikBag.setStatus(formsStatusMap.success);
            }
            dispatch(setSingleShoppingList(payload.data));
            break;

        case PUT_SHOPPING_LIST_FAIL:
            const putFailFormikBag = meta.previousAction?.meta?.formikBag;
            if (putFailFormikBag) {
                putFailFormikBag.setStatus(formsStatusMap.fail);
                putFailFormikBag.setErrors(formatFormikErrors(action.error));
            }
            break;

        case DELETE_SHOPPING_LIST:
            const deleteShoppingList = {
                types: [
                    DELETE_SHOPPING_LIST_REQUEST,
                    DELETE_SHOPPING_LIST_SUCCESS,
                    DELETE_SHOPPING_LIST_FAIL,
                ],
                payload: {
                    request: {
                        method: 'delete',
                        url: config.endpoints.shoppingLists.single.delete(payload),
                        headers: { ...getAuthorizationBearerHeader() },
                    },
                },
                meta: { ...meta, entity: SINGLE_SHOPPING_LIST_CHANGE },
            };
            dispatch(deleteShoppingList);
            break;

        case DELETE_SHOPPING_LIST_SUCCESS:
            dispatch(clearShoppingLists());
            break;

        case CLEAR_SINGLE_SHOPPING_LIST:
            dispatch(statusIdle(SINGLE_SHOPPING_LIST));
            dispatch(statusIdle(SINGLE_SHOPPING_LIST_CHANGE));
            dispatch(restoreInitialState(SINGLE_SHOPPING_LIST));
            break;

        default:
            break;
    }
};

export default singleShoppingListMiddleware;
