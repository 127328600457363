import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { useDispatch } from 'react-redux';

import { container, sliderBox, imgBox, button, icon } from './injectable-slider.module.scss';
import VideoPlayIcon from '../../assets/images/svg/video-play.svg';
import QueryEdgeNode from '../../models/query-edge-node.model';
import { IInjectableSlider } from '../../models/injectable-slider.model';
import getNodes from '../../utills/get-nodes';
import { addModalAction } from '../../redux/actions/actions-modals';

import Slider from '../hoc/slider';
import VideoModal from './custom-modals/video-modal';

interface IInjectableSliderProps {
    className?: string;
    sliderId: number;
}

interface InjectableSliderQueryResult {
    allInjectableSlider: QueryEdgeNode<IInjectableSlider>;
}

const InjectableSlider: React.FC<IInjectableSliderProps> = ({ className = '', sliderId }) => {
    const { allInjectableSlider } = useStaticQuery<InjectableSliderQueryResult>(query);
    const sliders = getNodes(allInjectableSlider);
    const slider = sliders.find((slider) => slider.injectableSliderId === sliderId);
    const slides = slider?.media;
    const dispatch = useDispatch();

    const handlePlayClick = (videoUrl: string) => {
        return () => {
            dispatch(
                addModalAction({
                    fullWidth: true,
                    renderComponent: (modalId: number) => (
                        <VideoModal modalId={modalId} videoUrl={videoUrl} autoplay={true} />
                    ),
                })
            );
        };
    };

    if (!slides || !slides.length) return null;

    return (
        <div className={`${container} ${className}`}>
            <Slider className={sliderBox}>
                {slides.map((slide) => {
                    const videoUrl =
                        slide.alt && slide.alt.startsWith('https://') ? slide.alt : null;
                    return (
                        <div key={`slide-${slide.fileId}`} className={imgBox}>
                            <img src={slide.url} alt={slide.alt || ''} />
                            {videoUrl && (
                                <button onClick={handlePlayClick(videoUrl)} className={button}>
                                    <VideoPlayIcon className={icon} />
                                </button>
                            )}
                        </div>
                    );
                })}
            </Slider>
        </div>
    );
};

const query = graphql`
    query {
        allInjectableSlider {
            edges {
                node {
                    ...injectableSliderFields
                }
            }
        }
    }
`;

export default InjectableSlider;
