import axios from 'axios';

import { config } from '../config';
import { getAuthorizationBearerHeader } from '../utills/get-authorization-bearer-header';

export const getExercise = (id) =>
    axios.get(config.endpoints.workout.exercise(id), {
        headers: { ...getAuthorizationBearerHeader() },
    });

export const getExerciseList = (page, pageSize, sort) =>
    axios.get(config.endpoints.workout.exerciseCollection(page, pageSize, sort), {
        headers: { ...getAuthorizationBearerHeader() },
    });

export const getTrainingsList = (page, pageSize, sort) =>
    axios.get(config.endpoints.workout.trainingsCollection(page, pageSize, sort), {
        headers: { ...getAuthorizationBearerHeader() },
    });

export const getTraining = (id) =>
    axios.get(config.endpoints.workout.training(id), {
        headers: { ...getAuthorizationBearerHeader() },
    });
