import { getUserTokenData } from './get-user-token-data';

export function getAuthorizationBearerHeader() {
    const tokenData = getUserTokenData();
    if (tokenData) {
        const token = tokenData.token;
        return {
            Authorization: `Bearer ${token}`,
        };
    }
    return {};
}
