import React from 'react';

import {
    content,
    name,
    text,
    icon,
    card,
    imgBox,
    ratioClass,
} from './metamorphosis-card.module.scss';
import QuoteIcon from '../../assets/images/svg/icon-quote.svg';

import RatioImage from '../atoms/ratio-image';
import Markdown from '../hoc/markdown';

const MetamorphosisCard = ({ cardData = {}, className = '' }) => {
    const alt = `Metamorfoza ze Strefą Przemian - ${cardData.author}`;

    return (
        <div className={`${card} ${className}`}>
            <div className={imgBox}>
                <RatioImage
                    ratioClass={ratioClass}
                    src={cardData.imageUrl}
                    alt={alt}
                    objectFit="cover"
                    style={{ height: '100%' }}
                />
            </div>
            <div className={content}>
                <div className={icon}>
                    <QuoteIcon />
                </div>
                <Markdown className={`ck-content ${text}`}>{cardData.content}</Markdown>
                <p className={name}>~ {cardData.author}</p>
            </div>
        </div>
    );
};

export default MetamorphosisCard;
