exports.components = {
  "component---src-pages-app-js": () => import("./../../../src/pages/app.js" /* webpackChunkName: "component---src-pages-app-js" */),
  "component---src-pages-cennik-js": () => import("./../../../src/pages/cennik.js" /* webpackChunkName: "component---src-pages-cennik-js" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-metamorfozy-js": () => import("./../../../src/pages/metamorfozy.js" /* webpackChunkName: "component---src-pages-metamorfozy-js" */),
  "component---src-pages-regulamin-tsx": () => import("./../../../src/pages/regulamin.tsx" /* webpackChunkName: "component---src-pages-regulamin-tsx" */),
  "component---src-pages-zamowienie-js": () => import("./../../../src/pages/zamowienie.js" /* webpackChunkName: "component---src-pages-zamowienie-js" */),
  "component---src-templates-about-js": () => import("./../../../src/templates/about.js" /* webpackChunkName: "component---src-templates-about-js" */),
  "component---src-templates-auth-js": () => import("./../../../src/templates/auth.js" /* webpackChunkName: "component---src-templates-auth-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-html-error-message-js": () => import("./../../../src/templates/html-error-message.js" /* webpackChunkName: "component---src-templates-html-error-message-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-product-js": () => import("./../../../src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */),
  "component---src-templates-shop-js": () => import("./../../../src/templates/shop.js" /* webpackChunkName: "component---src-templates-shop-js" */)
}

