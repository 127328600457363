import { config } from '../../config';
import { types, FILES_TO_DOWNLOAD, setFilesToDownload } from './files-to-download.actions';
import { getAuthorizationBearerHeader } from '../../utills/get-authorization-bearer-header';

const {
    GET_FILES_TO_DOWNLOAD,
    SEND_FILES_TO_DOWNLOAD_REQUEST,
    SEND_FILES_TO_DOWNLOAD_SUCCESS,
    SEND_FILES_TO_DOWNLOAD_FAIL,
} = types;

const filesToDownloadMiddleware = (state) => (next) => (action) => {
    const { dispatch } = state;
    const { type, payload } = action;
    next(action);

    switch (type) {
        case GET_FILES_TO_DOWNLOAD:
            const fetchData = {
                types: [
                    SEND_FILES_TO_DOWNLOAD_REQUEST,
                    SEND_FILES_TO_DOWNLOAD_SUCCESS,
                    SEND_FILES_TO_DOWNLOAD_FAIL,
                ],
                payload: {
                    request: {
                        url: config.endpoints.filesToDownload(payload.page, payload.perPage),
                        headers: { ...getAuthorizationBearerHeader() },
                    },
                },
                meta: {
                    entity: FILES_TO_DOWNLOAD,
                },
            };
            dispatch(fetchData);
            break;

        case SEND_FILES_TO_DOWNLOAD_SUCCESS:
            dispatch(setFilesToDownload(payload.data));
            break;

        default:
            break;
    }
};

export default filesToDownloadMiddleware;
