import axios from 'axios';

import { config } from '../config';
import { getAuthorizationBearerHeader } from '../utills/get-authorization-bearer-header';

export const getNewOrderPayment = (paymentId) => {
    return axios.patch(
        config.endpoints.shop.orderPayment(paymentId),
        {},
        {
            headers: { ...getAuthorizationBearerHeader() },
        }
    );
};
