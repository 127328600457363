import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import { graphql, useStaticQuery } from 'gatsby';

import {
    main,
    content,
    title,
    list,
    registerFormBox,
    registerForm,
    paymentBox,
    paymentTitle,
    paymentSpace,
    paymentPreview,
    summaryBox,
    summary,
    consentForm,
    consentFormDisclaimer,
    checkbox,
    submitButton,
    orderBox,
    cartEmpty,
    description,
    span,
} from './shop-order-summary.module.scss';
import PaymentMethodsIcon from '../assets/images/svg/payment-methods.svg';
import { ICart } from '../models/cart.model';
import { EProductKind } from '../models/product-kind.enum';
import { config } from '../config';
import { copy } from '../copy';
import { initialState as orderInitialState } from '../redux/shop/order/order.reducer';
import { selectCart, selectCartIsLoading } from '../redux/shop/cart/cart.selectors';
import { selectUserData } from '../redux/user/user.selectors';
import { onlyText } from 'react-children-utilities';
import {
    orderValidate,
    setOrderConsents,
    setOrderRegisterData,
    submitOrder,
} from '../redux/shop/order/order.actions';
import { selectOrderIsLoading } from '../redux/shop/order/order.selectors';

import SEO from '../components/seo';
import Main from '../layouts/main';
import CartListItem from '../components/molecules/cart/cart-list-item';
import Title from '../components/atoms/title';
import Input from '../components/atoms/form-poc/input';
import Separator from '../components/atoms/separator';
import PaymentPreview from '../components/atoms/payment-preview';
import ShopStepOrderSummary from '../components/organisms/shop-step-order-summary';
import DiscountCodeForm from '../components/organisms/discount-code-form';
import CheckAllCheckbox from '../components/atoms/form-poc/check-all-checkbox';
import Checkbox from '../components/atoms/form-poc/checkbox';
import ReadMore from '../components/atoms/read-more';
import CartButton from '../components/atoms/cart-button';
import Loader from '../components/atoms/loader';
import LinkButton from '../components/atoms/link-button';
import ProductDescription from '../components/organisms/product-description';

const { consentsMap } = config;
const { required, email } = config.messages.form;
const { consents } = copy;

interface IShopOrderSummaryQueryResult {
    orderSummaryContent: { content: string };
}

const ShopOrderSummary: React.FC = () => {
    const { orderSummaryContent } = useStaticQuery<IShopOrderSummaryQueryResult>(query);
    const dispatch = useDispatch();

    const cart: ICart = useSelector(selectCart);
    const isCartLoading: boolean = useSelector(selectCartIsLoading);
    const isUserLogged = useSelector(selectUserData) !== null;
    const isOrderLoading = useSelector(selectOrderIsLoading);

    const consentFormRef = useRef<FormikProps<
        ReturnType<typeof getConsentFormInitialValues>
    > | null>(null);
    const registerFormRef = useRef<FormikProps<typeof registerFormInitialValues> | null>(null);
    const registerFormBoxRef = useRef<HTMLDivElement | null>(null);

    const hasSubscriptionSp = cart.items.some((cartItem) => !!cartItem.subscriptionSp);
    const hasSubscription = cartHasProductKind(cart, EProductKind.Subscription);

    const handleOrderSubmit = async () => {
        if (!consentFormRef.current) return;
        await consentFormRef.current.submitForm();
        if (!isUserLogged) {
            if (!registerFormRef.current) return;
            await registerFormRef.current.submitForm();
            if (!registerFormRef.current.isValid) {
                registerFormBoxRef.current?.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                });
                return;
            }
            dispatch(
                setOrderRegisterData({
                    registerUser: true,
                    userData: registerFormRef.current.values,
                })
            );
        } else {
            dispatch(
                setOrderRegisterData({ registerUser: false, userData: orderInitialState.userData })
            );
        }

        if (!consentFormRef.current.isValid) return;
        const consentsArr = [];
        for (const [key, value] of Object.entries(consentFormRef.current.values.consents)) {
            consentsArr.push({
                source: consentsMap.source.order,
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                key: consentsMap.key[key],
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                content: onlyText(consents[key]),
                agreed: value ? 1 : 0,
            });
        }

        dispatch(setOrderConsents(consentsArr));
        dispatch(submitOrder());
    };

    useEffect(() => {
        dispatch(orderValidate());
    }, [dispatch]);

    return (
        <>
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/*@ts-ignore*/}
            <SEO title="Podsumowanie zamówienia" />
            <Main isShopCart={true} hasJoinBox={false} hasNewsletterForm={false} className={main}>
                {!isCartLoading && cart.items.length === 0 && (
                    <div className={cartEmpty}>
                        <Title Tag="h1" className={title}>
                            {texts.cartEmptyTitle}
                        </Title>
                        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                        {/*@ts-ignore*/}
                        <LinkButton to="/sklep">{texts.cartEmptyLink}</LinkButton>
                    </div>
                )}
                {cart.items.length > 0 && (
                    <div className={content}>
                        <Title Tag="h1" className={title}>
                            {hasSubscriptionSp ? texts.titleSubscriptionSp : texts.title}
                        </Title>
                        <div className={orderBox}>
                            <div className={list}>
                                {isCartLoading && <Loader />}
                                {!isCartLoading &&
                                    cart.items.map((cartItem) => {
                                        return (
                                            <CartListItem
                                                key={`cart-item-${cartItem.itemId}`}
                                                item={cartItem}
                                                context="order-summary"
                                                noSubscriptionPrice={true}
                                            />
                                        );
                                    })}
                            </div>
                            {!isUserLogged && (
                                <div className={registerFormBox} ref={registerFormBoxRef}>
                                    <Formik
                                        innerRef={registerFormRef}
                                        initialValues={registerFormInitialValues}
                                        validationSchema={registerFormValidationSchema}
                                        onSubmit={() => {}}
                                    >
                                        {() => (
                                            <Form className={registerForm}>
                                                {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                                                {/*@ts-ignore*/}
                                                <Input name="firstName" placeholder="Imię" />
                                                {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                                                {/*@ts-ignore*/}
                                                <Input name="lastName" placeholder="Nazwisko" />
                                                {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                                                {/*@ts-ignore*/}
                                                <Input
                                                    containerClass={span}
                                                    name="email"
                                                    type="email"
                                                    placeholder="Adres e-mail"
                                                />
                                                {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                                                {/*@ts-ignore*/}
                                                <Input
                                                    containerClass={span}
                                                    name="phone"
                                                    type="tel"
                                                    placeholder="Numer telefonu"
                                                />
                                            </Form>
                                        )}
                                    </Formik>
                                </div>
                            )}
                            <div className={paymentBox}>
                                <p className={paymentTitle}>{texts.paymentMethod}</p>
                                <Separator className={paymentSpace} />
                                <p>{texts.paymentChoice}</p>
                                <PaymentPreview
                                    className={paymentPreview}
                                    text={texts.paymentOnline}
                                    Icon={PaymentMethodsIcon}
                                />
                            </div>
                        </div>
                        <div className={summaryBox}>
                            <ShopStepOrderSummary className={summary} cartItems={cart.items} />
                            <DiscountCodeForm />
                            <Formik
                                innerRef={consentFormRef}
                                initialValues={getConsentFormInitialValues({
                                    hasSubscription,
                                })}
                                validationSchema={getConsentFormValidationSchema({
                                    hasSubscription,
                                })}
                                onSubmit={() => {}}
                                enableReinitialize={true}
                            >
                                {() => (
                                    <Form className={consentForm}>
                                        <div className={consentFormDisclaimer}>
                                            {texts.consentDisclaimer}
                                        </div>
                                        <CheckAllCheckbox
                                            checkboxNames={
                                                [
                                                    'consents.regulationsOrder',
                                                    'consents.rodoOrder',
                                                    hasSubscription
                                                        ? 'consents.rodoHealthData'
                                                        : null,
                                                ].filter((name) => name !== null) as string[]
                                            }
                                            containerClass={checkbox}
                                        />
                                        <Checkbox
                                            name="consents.regulationsOrder"
                                            containerClass={checkbox}
                                        >
                                            {consents.regulationsOrder}
                                        </Checkbox>
                                        <Checkbox
                                            name="consents.rodoOrder"
                                            containerClass={checkbox}
                                        >
                                            {consents.rodoOrder}
                                        </Checkbox>
                                        {hasSubscription && (
                                            <Checkbox
                                                name="consents.rodoHealthData"
                                                containerClass={checkbox}
                                            >
                                                <ReadMore>{consents.rodoHealthData}</ReadMore>
                                            </Checkbox>
                                        )}
                                        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                                        {/*@ts-ignore*/}
                                        <CartButton
                                            onClick={handleOrderSubmit}
                                            type="button"
                                            className={submitButton}
                                            fullSize={true}
                                            transitions={false}
                                            title="ZAMÓW Z OBOWIĄZKIEM ZAPŁATY"
                                            disabled={isCartLoading || isOrderLoading}
                                        />
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                )}
                {orderSummaryContent?.content && (
                    <ProductDescription
                        className={description}
                        description={orderSummaryContent.content}
                    />
                )}
            </Main>
        </>
    );
};

function cartHasProductKind(cart: ICart, kindId: EProductKind) {
    return cart?.items?.some((item) => item.product.kind === kindId);
}

interface IGetConsentFormInitialValuesConfig {
    hasSubscription: boolean;
}

function getConsentFormInitialValues({ hasSubscription }: IGetConsentFormInitialValuesConfig) {
    return {
        consents: {
            regulationsOrder: false,
            rodoOrder: false,
            ...(hasSubscription ? { rodoHealthData: false, warrantyOne: false } : {}),
        },
    };
}

interface IGetConsentFormValidationSchemaConfig {
    hasSubscription: boolean;
}

function getConsentFormValidationSchema({
    hasSubscription,
}: IGetConsentFormValidationSchemaConfig) {
    return Yup.object({
        consents: Yup.object({
            regulationsOrder: consentValidations.regulationsOrder,
            rodoOrder: consentValidations.rodoOrder,
            ...(hasSubscription ? { rodoHealthData: consentValidations.rodoHealthData } : {}),
        }),
    });
}

const registerFormInitialValues = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
};

const registerFormValidationSchema = Yup.object({
    firstName: Yup.string().required(required),
    lastName: Yup.string().required(required),
    email: Yup.string().email(email).required(required),
});

const consentValidations = {
    regulationsOrder: Yup.boolean().required(required).oneOf([true], required),
    rodoOrder: Yup.boolean().required(required).oneOf([true], required),
    rodoHealthData: Yup.boolean().required(required).oneOf([true], required),
};

const texts = {
    cartEmptyLink: 'Idź do sklepu',
    cartEmptyTitle: 'Twój koszyk jest pusty!',
    consentDisclaimer: (
        <>
            Strefa Przemian to&nbsp;unikalne miejsce, ponieważ naszym priorytetem jest m.in. dbanie
            o&nbsp;Twój komfort i&nbsp;bezpieczeństwo. Przeczytaj treść poniższych punktów, aby
            przejść dalej:
        </>
    ),
    paymentChoice:
        'Wybór metody płatności nastąpi po kliknięciu przycisku "Zamów z obowiązkiem zapłaty".',
    paymentMethod: 'Sposób płatności',
    paymentOnline: 'Płatność online',
    title: 'Podsumowanie zamówienia',
    titleSubscriptionSp: (
        <>
            Gratulujemy decyzji!
            <br />
            <span>
                Należysz do 1% osób, które odważnie działają, by osiągnąć swoje cele. Już za chwilę
                zyskasz dostęp do wszystkich korzyści naszej współpracy – dokończ zakup i zacznijmy
                już od dziś!
            </span>
        </>
    ),
};

export const query = graphql`
    query {
        orderSummaryContent {
            content
        }
    }
`;

export default ShopOrderSummary;
