import React from 'react';
import { errorHandler } from '../../communication/utils';
import AddMealTypeModal from '../../components/organisms/custom-modals/add-meal-type-modal';
import AddPlanTemplateModal from '../../components/organisms/custom-modals/add-plan-template-modal';
import RecipeModal from '../../components/organisms/custom-modals/recipe-modal';
import RecipeSearchModal from '../../components/organisms/custom-modals/recipe-search-modal';

import { config } from '../../config';
import { getMealTypesForSelect } from '../../utills/get-meal-types-for-select';
import {
    delPositionsByMelaType,
    excludedTypesArr,
    generateAndPopulateUnits,
    generateColumns,
    generateRows,
    mapArrayToObj,
    weekDaysArr,
} from '../../utills/meal-planer';
import { addModalAction, removeModalAction } from '../actions/actions-modals';
import { clearAllAlerts, setAlert } from '../alerts/alerts.actions';
import { MEAL_PLANER_TEMPLATE, types } from './meal-planer-template.actions';
import { initialState } from './meal-planer-template.reducer';
import { getAuthorizationBearerHeader } from '../../utills/get-authorization-bearer-header';

const { mealTypesMap, coordinates } = config.mealPlanner;

const endpoint = config.endpoints.mealPlanner.templates;

const successMsg = {
    type: 'success',
    content: `Udało się zaktualizować szablon`,
};

const mealPlanerTemplateMiddleware = (state) => (next) => (action) => {
    const { dispatch, getState } = state;
    const { type, payload, meta } = action;
    next(action);

    let current = getState().mealPlanerTemplate.current;
    let { id, planerUnits, rows } = getState().mealPlanerTemplate.current;
    let templateList = getState().mealPlanerTemplate.templateList;
    let error, columns, units, newRows, newPositions, newTemplateList, tags;

    switch (type) {
        case types.GET_TEMPLATE_LIST:
            dispatch(clearAllAlerts());
            dispatch({
                type: types.FETCH_TEMPLATE_DATA,
                payload: {
                    url: `${endpoint}`,
                    params: {
                        ...payload?.params,
                        'per-page': 50,
                    },
                },
            });
            break;

        case types.CREATE_TEMPLATE:
            dispatch(clearAllAlerts());
            dispatch({
                type: types.FETCH_TEMPLATE_ACTION,
                payload: {
                    method: 'post',
                    body: payload,
                    url: `${endpoint}`,
                },
            });
            break;

        case types.FETCH_TEMPLATE_DATA:
            dispatch(clearAllAlerts());
            dispatch({
                types: [
                    types.SEND_PLANER_TEMPLATE_REQUEST,
                    types.SEND_PLANER_TEMPLATE_SUCCESS,
                    types.SEND_PLANER_TEMPLATE_FAIL,
                ],
                payload: {
                    request: {
                        method: payload.method ? payload.method : 'get',
                        data: payload.body ? payload.body : null,
                        url: payload.url,
                        params: payload.params ?? null,
                        headers: { ...getAuthorizationBearerHeader() },
                    },
                },
                meta: {
                    entity: MEAL_PLANER_TEMPLATE,
                    ...action.meta,
                },
            });
            break;

        case types.SEND_PLANER_TEMPLATE_SUCCESS:
            dispatch(clearAllAlerts());

            if (meta.previousAction.meta.originAction === types.DEL_TEMPLATE) {
                newTemplateList = templateList.filter(
                    (item) => item.id !== meta.previousAction.meta.id
                );

                dispatch({
                    type: types.SET_PLANER_TEMPLATE_DATA,
                    payload: initialState.current,
                });
            } else {
                newTemplateList = payload.data.items;
            }

            dispatch({
                type: types.SET_PLANER_TEMPLATE_LIST,
                payload: newTemplateList,
            });
            break;

        case types.ADD_ITEM:
            dispatch({
                type: types.SEARCH_RECIPES,
                payload: payload,
            });
            break;

        // modal actions
        case types.DEL_TEMPLATE:
            dispatch(
                addModalAction({
                    modalKey: 'CONFIRMATION_MODAL',
                    modalProps: {
                        content: 'Chcesz usunąć ten szablon?',
                        onConfirm: () => {
                            dispatch({
                                type: types.FETCH_TEMPLATE_DATA,
                                payload: {
                                    method: 'delete',
                                    url: `${endpoint}/${payload.id}`,
                                },
                                meta: {
                                    originAction: types.DEL_TEMPLATE,
                                    id: payload.id,
                                },
                            });
                        },
                    },
                })
            );
            break;

        case types.EDIT_TEMPLATE:
            dispatch(
                addModalAction({
                    renderComponent: (modalId) => (
                        <AddPlanTemplateModal
                            modalId={modalId}
                            plan={{
                                name: current.name,
                                status: current.status,
                                tags: current.tags,
                                auth: current.auth,
                            }}
                            onSubmit={(data) => {
                                dispatch({
                                    type: types.FETCH_TEMPLATE_ACTION,
                                    payload: {
                                        method: 'patch',
                                        body: { ...data },
                                        url: `${endpoint}/${current.id}`,
                                        params: {
                                            expand: `positions,tags`,
                                        },
                                    },
                                    meta: { modalId },
                                });
                            }}
                        />
                    ),
                })
            );
            break;

        case types.SHOW_ITEM_PREVIEW:
            dispatch(
                addModalAction({
                    renderComponent: (modalId) => (
                        <RecipeModal modalId={modalId} id={payload.positionId} isTemplate={true} />
                    ),
                })
            );
            break;

        case types.ADD_MEAL_TYPE:
            dispatch(
                addModalAction({
                    renderComponent: (modalId) => (
                        <AddMealTypeModal
                            modalId={modalId}
                            options={getMealTypesForSelect(
                                mealTypesMap,
                                true,
                                excludedTypesArr(rows)
                            )}
                            onSubmit={(formValues) => {
                                dispatch(removeModalAction(modalId));
                                dispatch({
                                    type: types.SET_PLANER_TEMPLATE_DATA,
                                    payload: {
                                        rows: {
                                            ...rows,
                                            [formValues.mealType.key]: {
                                                ...rows[formValues.mealType.key],
                                                dimension: 'auto',
                                            },
                                        },
                                    },
                                });
                            }}
                        />
                    ),
                })
            );
            break;

        case types.DEL_ITEM:
            dispatch(
                addModalAction({
                    modalKey: 'CONFIRMATION_MODAL',
                    modalProps: {
                        content: 'Chcesz usunąć tą pozycję?',
                        onConfirm: () => {
                            dispatch({
                                type: types.FETCH_DEL_POSITION,
                                payload: payload,
                            });
                        },
                    },
                })
            );
            break;

        case types.DEL_MEAL_TYPE:
            dispatch(
                addModalAction({
                    modalKey: 'CONFIRMATION_MODAL',
                    modalProps: {
                        content: 'Chcesz usunąć wszystkie pozycje z tego typu posiłku?',
                        onConfirm: () => {
                            dispatch({
                                type: types.FETCH_DEL_MEAL_TYPE,
                                payload: payload,
                            });
                        },
                    },
                })
            );
            break;

        case types.SEARCH_RECIPES:
            dispatch(
                addModalAction({
                    renderComponent: (modalId) => (
                        <RecipeSearchModal
                            modalId={modalId}
                            isTemplate={true}
                            onRecipeSelect={(recipe) => {
                                dispatch({
                                    type: types.FETCH_ADD_RECIPE,
                                    payload: { ...recipe, ...payload },
                                    meta: { modalId },
                                });
                            }}
                        />
                    ),
                })
            );
            break;

        case types.FETCH_ADD_RECIPE:
            dispatch({
                type: types.FETCH_POSITION,
                payload: {
                    body: [
                        {
                            action: 'add',
                            day: payload.date,
                            mealTypeId: payload.mealTypeId,
                            recipeId: payload.recipeId,
                            mealId: payload.mealId,
                            portions: 1,
                            sequence: payload.sequence,
                        },
                    ],
                },
                meta: action.meta,
            });
            break;

        case types.UPDATE_ITEM:
            dispatch({
                type: types.FETCH_POSITION,
                payload: {
                    body: [
                        {
                            action: 'update',
                            positionId: payload.positionId,
                            day: payload.date,
                            portions: payload.portions,
                            mealTypeId: payload.mealTypeId,
                            sequence: payload.sequence,
                        },
                    ],
                },
                meta: action.meta,
            });
            break;

        case types.FETCH_DEL_POSITION:
            dispatch({
                type: types.FETCH_POSITION,
                payload: {
                    body: [
                        {
                            action: 'delete',
                            positionId: payload,
                        },
                    ],
                },
                meta: action.meta,
            });
            break;

        case types.FETCH_DEL_MEAL_TYPE:
            dispatch({
                type: types.FETCH_POSITION,
                payload: {
                    body: delPositionsByMelaType(payload, planerUnits),
                },
                meta: action.meta,
            });
            break;

        case types.GET_TEMPLATE_DATA:
            dispatch(clearAllAlerts());
            dispatch({
                type: types.FETCH_TEMPLATE_ACTION,
                payload: {
                    url: `${endpoint}/${payload.id}`,
                },
            });
            break;

        case types.FETCH_TEMPLATE_ACTION:
            dispatch(clearAllAlerts());
            dispatch({
                types: [
                    types.SEND_PLANER_TEMPLATE_ACTION_REQUEST,
                    types.SEND_PLANER_TEMPLATE_ACTION_SUCCESS,
                    types.SEND_PLANER_TEMPLATE_ACTION_FAIL,
                ],
                payload: {
                    request: {
                        method: payload.method ? payload.method : 'get',
                        data: payload.body ? payload.body : null,
                        url: payload.url,
                        headers: { ...getAuthorizationBearerHeader() },
                        params: {
                            expand: `positions,tags,positions.recipe,positions.recipe.preparationSteps,positions.recipe.ingredients`,
                        },
                    },
                },
                meta: {
                    entity: MEAL_PLANER_TEMPLATE,
                    ...action.meta,
                },
            });
            break;

        case types.SEND_PLANER_TEMPLATE_ACTION_SUCCESS:
            if (action.meta.previousAction.meta?.modalId) {
                dispatch(removeModalAction(action.meta.previousAction.meta.modalId));
            }
            newPositions = mapArrayToObj(payload.data.positions);
            columns = generateColumns(weekDaysArr, coordinates);
            units = generateAndPopulateUnits(mealTypesMap, weekDaysArr, newPositions);
            newRows = generateRows(units, mealTypesMap, coordinates);

            tags = [];
            for (const item of payload.data.tags) {
                tags.push(item.tagId.toString());
            }

            dispatch({
                type: types.SET_PLANER_TEMPLATE_DATA,
                payload: {
                    id: payload.data.id,
                    name: payload.data.name,
                    tags: tags,
                    positions: newPositions,
                    status: payload.data.status,
                    planerUnits: units,
                    auth: payload.data.auth,
                    columns: columns,
                    rows: newRows,
                    dates: weekDaysArr,
                },
            });
            break;

        case types.FETCH_POSITION:
            dispatch(clearAllAlerts());
            dispatch({
                types: [
                    types.SEND_PLANER_TEMPLATE_POSITION_REQUEST,
                    types.SEND_PLANER_TEMPLATE_POSITION_SUCCESS,
                    types.SEND_PLANER_TEMPLATE_POSITION_FAIL,
                ],
                payload: {
                    request: {
                        method: 'post',
                        data: payload.body ? payload.body : null,
                        url: `${endpoint}/${id}/positions`,
                        params: {
                            expand: `positions,tags,positions.recipe,positions.recipe.preparationSteps,positions.recipe.ingredients`,
                        },
                        headers: { ...getAuthorizationBearerHeader() },
                    },
                },
                meta: {
                    entity: MEAL_PLANER_TEMPLATE,
                    ...action.meta,
                },
            });
            break;

        case types.SEND_PLANER_TEMPLATE_POSITION_SUCCESS:
            dispatch(setAlert(successMsg));
            if (action.meta.previousAction.meta?.modalId) {
                dispatch(removeModalAction(action.meta.previousAction.meta.modalId));
            }

            newPositions = mapArrayToObj(payload.data.positions);
            columns = generateColumns(weekDaysArr, coordinates);
            units = generateAndPopulateUnits(mealTypesMap, weekDaysArr, newPositions);
            newRows = generateRows(units, mealTypesMap, coordinates);

            dispatch({
                type: types.SET_PLANER_TEMPLATE_DATA,
                payload: {
                    id: payload.data.templateId,
                    name: payload.data.name,
                    tags: payload.data.tags,
                    positions: newPositions,
                    status: payload.data.status,
                    planerUnits: units,
                    columns: columns,
                    rows: newRows,
                    dates: weekDaysArr,
                },
            });
            break;

        case types.SEND_PLANER_TEMPLATE_ACTION_FAIL:
        case types.SEND_PLANER_TEMPLATE_FAIL:
            error = errorHandler(action.error);
            error.map((err) => dispatch(setAlert(err, MEAL_PLANER_TEMPLATE)));
            break;
        // no default
    }
};

export default mealPlanerTemplateMiddleware;
