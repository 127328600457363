import { config } from '../../config';

import {
    ADDRESS,
    setAddressAction,
    restoreInitialStateAction,
    types,
    clearAddressAction,
} from './address.actions.js';
import { statusIdle } from '../ui/ui.actions';
import { navigate } from '@reach/router';
import { getAbsolutePath } from '../../routes';
import { formatFormikErrors } from '../../communication/utils';
import { addModalAction } from '../actions/actions-modals';
import { clearAddressesAction, getAddressesAction } from '../addresses/addresses.actions';
import { getAuthorizationBearerHeader } from '../../utills/get-authorization-bearer-header';

const {
    GET_ADDRESS,
    CREATE_ADDRESS,
    EDIT_ADDRESS,
    DELETE_ADDRESS,
    FETCH_ADDRESS_REQUEST,
    FETCH_ADDRESS_SUCCESS,
    FETCH_ADDRESS_FAIL,
    CREATE_ADDRESS_REQUEST,
    CREATE_ADDRESS_SUCCESS,
    CREATE_ADDRESS_FAIL,
    EDIT_ADDRESS_REQUEST,
    EDIT_ADDRESS_SUCCESS,
    EDIT_ADDRESS_FAIL,
    DELETE_ADDRESS_REQUEST,
    DELETE_ADDRESS_SUCCESS,
    DELETE_ADDRESS_FAIL,
    CLEAR_ADDRESS,
} = types;

const addressMiddleware = (state) => (next) => (action) => {
    const { dispatch } = state;
    const { type, payload, meta } = action;
    next(action);

    switch (type) {
        case GET_ADDRESS:
            const fetchAddressesConfig = {
                types: [FETCH_ADDRESS_REQUEST, FETCH_ADDRESS_SUCCESS, FETCH_ADDRESS_FAIL],
                payload: {
                    request: {
                        url: config.endpoints.addresses.get.single(payload),
                        headers: { ...getAuthorizationBearerHeader() },
                    },
                },
                meta: {
                    entity: ADDRESS,
                },
            };
            dispatch(fetchAddressesConfig);
            break;

        case FETCH_ADDRESS_SUCCESS:
            dispatch(setAddressAction(payload.data));
            break;

        case CREATE_ADDRESS:
            meta.formikBag.setStatus(config.formsStatusMap.loading);
            const createAddressConfig = {
                types: [CREATE_ADDRESS_REQUEST, CREATE_ADDRESS_SUCCESS, CREATE_ADDRESS_FAIL],
                payload: {
                    request: {
                        method: 'post',
                        data: payload,
                        url: config.endpoints.addresses.create,
                        headers: { ...getAuthorizationBearerHeader() },
                    },
                },
                meta: {
                    entity: ADDRESS,
                    formikBag: meta.formikBag,
                    addressTypeId: payload.type,
                },
            };
            dispatch(createAddressConfig);
            break;

        case EDIT_ADDRESS:
            meta.formikBag.setStatus(config.formsStatusMap.loading);
            const editAddressConfig = {
                types: [EDIT_ADDRESS_REQUEST, EDIT_ADDRESS_SUCCESS, EDIT_ADDRESS_FAIL],
                payload: {
                    request: {
                        method: 'put',
                        data: payload.data,
                        url: config.endpoints.addresses.edit(payload.addressId),
                        headers: { ...getAuthorizationBearerHeader() },
                    },
                },
                meta: {
                    entity: ADDRESS,
                    formikBag: meta.formikBag,
                    addressTypeId: payload.data.type,
                },
            };
            dispatch(editAddressConfig);
            break;

        case CREATE_ADDRESS_SUCCESS:
        case EDIT_ADDRESS_SUCCESS:
            if (meta.previousAction.meta.addressTypeId === config.addressTypes.delivery.id) {
                navigate(getAbsolutePath('APP_MY_ACCOUNT_USER_ZONE_ADDRESSES_DELIVERY_HOME'));
            } else {
                navigate(getAbsolutePath('APP_MY_ACCOUNT_USER_ZONE_ADDRESSES_INVOICE_HOME'));
            }
            break;

        case CREATE_ADDRESS_FAIL:
        case EDIT_ADDRESS_FAIL:
            meta.previousAction.meta.formikBag.setStatus(config.formsStatusMap.fail);
            meta.previousAction.meta.formikBag.setErrors(formatFormikErrors(action.error));
            break;

        case DELETE_ADDRESS:
            const deleteAddressConfig = {
                types: [DELETE_ADDRESS_REQUEST, DELETE_ADDRESS_SUCCESS, DELETE_ADDRESS_FAIL],
                payload: {
                    request: {
                        method: 'delete',
                        url: config.endpoints.addresses.delete(payload),
                        headers: { ...getAuthorizationBearerHeader() },
                    },
                },
                meta: { entity: ADDRESS },
            };
            dispatch(deleteAddressConfig);
            break;

        case DELETE_ADDRESS_SUCCESS:
            dispatch(clearAddressesAction());
            dispatch(clearAddressAction());
            dispatch(getAddressesAction());
            break;

        case DELETE_ADDRESS_FAIL:
            dispatch(
                addModalAction({
                    type: 'error',
                    title: 'Coś poszło nie tak...',
                    content: 'Nie udało się usunąć adresu.',
                })
            );
            break;

        case CLEAR_ADDRESS:
            dispatch(statusIdle(ADDRESS));
            dispatch(restoreInitialStateAction());
            break;

        default:
            break;
    }
};

export default addressMiddleware;
