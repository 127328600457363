import axios from 'axios';

import { config } from '../config';
import { getAuthorizationBearerHeader } from '../utills/get-authorization-bearer-header';

export const saveMovieLog = (movieData) => {
    const dataString = JSON.stringify(movieData);
    const dataBase64 = btoa(dataString);
    return axios.post(config.endpoints.audit.movie, dataBase64, {
        headers: { ...getAuthorizationBearerHeader() },
    });
};
