import axios from 'axios';
import { config } from '../../../config';
import { getAuthorizationBearerHeader } from '../../../utills/get-authorization-bearer-header';

export const getMessagesData = ({ phraseString, countToDownload, profileId }) => {
    if (phraseString.length > 0) {
        return axios.get(
            config.endpoints.messages.findFetch({
                phraseString,
                countToDownload,
                profileId,
            }),
            {
                headers: { ...getAuthorizationBearerHeader() },
            }
        );
    } else {
        return axios.get(
            config.endpoints.messages.fetchThread({
                countToDownload,
                profileId,
            }),
            {
                headers: { ...getAuthorizationBearerHeader() },
            }
        );
    }
};

export const setMessagesData = (sendData, profileId) => {
    return axios.post(config.endpoints.messages.send(profileId), sendData, {
        headers: { ...getAuthorizationBearerHeader() },
    });
};
