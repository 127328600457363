// extracted by mini-css-extract-plugin
export var banner = "course-module--banner--MDaRH";
export var bannerContent = "course-module--banner-content--RBMVQ";
export var bannerRatioClass = "course-module--banner-ratio-class--UuR0E";
export var categoryCoursePillsHolder = "course-module--category-course-pills-holder--E65uP";
export var currentCategoryDescription = "course-module--current-category-description--kiKtV";
export var currentCategoryTittle = "course-module--current-category-tittle--4VjU4";
export var dataToDownloadItems = "course-module--data-to-download-items--jqwYs";
export var dataToDownloadTitle = "course-module--data-to-download-title--SU1K+";
export var dataToDownloadWrapper = "course-module--data-to-download-wrapper--ZrpDX";
export var downloadItem = "course-module--download-item--dZtsm";
export var info = "course-module--info--I1qma";
export var loader = "course-module--loader--XW11v";
export var movieAdditionalInformation = "course-module--movie-additional-information--vqwi-";
export var movieCourseDescription = "course-module--movie-course-description--wu1F2";
export var movieGroup = "course-module--movieGroup--9Bi9b";
export var restrictedFileButton = "course-module--restricted-file-button--phmsr";
export var separator = "course-module--separator--LtbH0";
export var subTitle = "course-module--sub-title--QNiwz";
export var tag = "course-module--tag--OGfx8";
export var titleCategoryWrapper = "course-module--title-category-wrapper--vuwtJ";
export var wrapper = "course-module--wrapper--CwIZ6";