import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { getImage, withArtDirection } from 'gatsby-plugin-image';

import { list, img, icon, banner, culinary, imageContent, fullWidth } from './culinary.module.scss';
import HeartIcon from '../../../assets/images/svg/icon-heart.svg';
import { getAbsolutePath } from '../../../routes';
import { entity } from '../../../rbac/permissions';

import List from '../../molecules/list/list';
import LinkBanner from '../../molecules/link-banner';
import UserCan from '../../../rbac/hoc';

const Culinary = () => {
    const {
        publicRecipesImg,
        yourRecipesImg,
        favoriteRecipesImg,
        planerImg,
        planerImgMobile,
    } = useStaticQuery(query);

    const culinaryAreas = [
        {
            id: 4,
            to: UserCan({
                action: entity.MEAL_PLANER_TEMPLATE,
                yes: () => getAbsolutePath('APP_MEAL_PLANER_TEMPLATE'),
                no: () => getAbsolutePath('APP_MEAL_PLANER'),
            }),
            description: `Planowanie posiłków jeszcze nigdy nie było tak proste i przyjemne! Sprawdź!`,
            image: withArtDirection(getImage(planerImg), [
                {
                    image: getImage(planerImgMobile),
                    media: `(max-width: 420px)`,
                },
            ]),
            title: 'Planer menu',
            className: `${banner} ${fullWidth}`,
            imgContentClass: `${imageContent}`,
            imgClass: img,
            onImageDescription: true,
            permissionEntity: entity.FOOD_PLANNER_ACCESS,
            disabled: UserCan({
                action: entity.FOOD_PLANNER_ACCESS,
                yes: () => false,
                no: () => true,
            }),
        },
        {
            id: 1,
            to: getAbsolutePath('APP_PUBLIC_RECIPES'),
            description: `Baza receptur dostępnych na platformie Strefa Przemian.`,
            image: getImage(publicRecipesImg),
            title: 'Wszystkie przepisy',
            className: banner,
        },
        {
            id: 2,
            to: getAbsolutePath('APP_YOUR_RECIPES'),
            description: `Lista stworzonych lub dodanych przez Ciebie receptur.`,
            image: getImage(yourRecipesImg),
            title: 'Twoje przepisy',
            className: banner,
            permissionEntity: entity.RECIPES,
            disabled: UserCan({
                action: entity.RECIPES,
                yes: () => false,
                no: () => true,
            }),
        },
        {
            id: 3,
            to: getAbsolutePath('APP_FAVORITE_RECIPES'),
            description: `Baza Twoich ulubionych receptur.`,
            image: getImage(favoriteRecipesImg),
            imgTitle: (
                <>
                    <HeartIcon className={icon} /> Ulubione przepisy
                </>
            ),
            title: 'Ulubione przepisy',
            className: banner,
        },
    ];

    return (
        <div className={culinary}>
            <List listClassName={list} ItemComponent={LinkBanner} items={culinaryAreas} path={''} />
        </div>
    );
};

const query = graphql`
    {
        publicRecipesImg: file(relativePath: { eq: "banner-public-recipes.png" }) {
            childImageSharp {
                gatsbyImageData(width: 500, quality: 100, layout: CONSTRAINED, placeholder: BLURRED)
            }
        }
        yourRecipesImg: file(relativePath: { eq: "banner-your-recipes.png" }) {
            childImageSharp {
                gatsbyImageData(width: 500, quality: 100, layout: CONSTRAINED, placeholder: BLURRED)
            }
        }
        favoriteRecipesImg: file(relativePath: { eq: "banner-favorite-recipes.png" }) {
            childImageSharp {
                gatsbyImageData(width: 500, quality: 100, layout: CONSTRAINED, placeholder: BLURRED)
            }
        }
        planerImg: file(relativePath: { eq: "banner-planer.png" }) {
            childImageSharp {
                gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: BLURRED)
            }
        }
        planerImgMobile: file(relativePath: { eq: "banner-planer-mobile.png" }) {
            childImageSharp {
                gatsbyImageData(width: 420, quality: 100, layout: FULL_WIDTH, placeholder: BLURRED)
            }
        }
    }
`;

export default Culinary;
