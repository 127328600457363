import { errorHandler } from '../../communication/utils';
import { config } from '../../config';
import { clearAllAlerts, setAlert } from '../alerts/alerts.actions';
import {
    fetchTraining,
    fetchTrainingList,
    PERSONAL_TRAINING,
    PERSONAL_TRAINING_CALENDAR,
    PERSONAL_TRAINING_DAY,
    PERSONAL_TRAINING_LIST,
    setCurrentMovie,
    setCurrentTraining,
    setKnownTraining,
    setTrainingCalendar,
    setTrainingDay,
    setTrainingList,
    types,
} from './personal-training.actions';
import { initialState } from './personal-training.reducer';
import { getFormattedDate } from '../../utills/date-utils';
import { arrayToArrayOfKeys, arrayToObject } from '../../utills/data-convertion';
import { getCurrentProfileId } from '../profile/profile.selectors';
import { getAuthorizationBearerHeader } from '../../utills/get-authorization-bearer-header';

const { endpoints } = config;

const personalTrainingMiddleware = (state) => (next) => (action) => {
    const { dispatch, getState } = state;
    const { type, payload } = action;
    next(action);

    let currentProfileId = getCurrentProfileId(getState());
    let { trainingsList, current } = getState().personalTrainings;
    let error, fetchConfig, daysObj, playerObj;

    switch (type) {
        // trigger actions
        case types.CHANGE_DAY:
            playerObj = {
                listType: initialState.current.listType,
                playlistMovieIndex: 0,
            };
            dispatch(setCurrentTraining({ date: payload, isPlaying: false }));
            dispatch(setCurrentMovie(playerObj));
            break;

        case types.PLAY_MOVIE:
            dispatch(setCurrentMovie(payload));
            break;

        // fetch triggers
        case types.GET_CURRENT_TRAINING:
            fetchConfig = {
                url: `${endpoints.personalTraining.current}/${currentProfileId}`,
            };

            dispatch(fetchTraining(fetchConfig));
            break;

        case types.GET_SINGLE_TRAINING:
            fetchConfig = {
                url: `${endpoints.personalTraining.single}/${payload}`,
            };

            dispatch(fetchTraining(fetchConfig));
            break;

        case types.GET_TRAININGS:
            fetchConfig = {
                url: `${endpoints.personalTraining.single}?page=${trainingsList.pagination.nextPageToFetch}`,
            };

            dispatch(fetchTrainingList(fetchConfig));
            break;

        // fetch
        case types.GET_TRAINING:
            dispatch(clearAllAlerts());
            dispatch({
                types: [
                    types.FETCH_SINGLE_TRAINING_REQUEST,
                    types.FETCH_SINGLE_TRAINING_SUCCESS,
                    types.FETCH_SINGLE_TRAINING_FAIL,
                ],
                payload: {
                    request: {
                        url: payload.url,
                        headers: { ...getAuthorizationBearerHeader() },
                    },
                },
                meta: {
                    entity: PERSONAL_TRAINING,
                    ...action.meta,
                },
            });
            break;

        case types.FETCH_SINGLE_TRAINING_SUCCESS:
            dispatch(
                setKnownTraining({
                    [payload.data.id]: payload.data,
                })
            );
            dispatch(
                setCurrentTraining({
                    id: payload.data.id,
                })
            );
            break;

        case types.GET_TRAINING_CALENDAR:
            dispatch(clearAllAlerts());
            dispatch({
                types: [
                    types.FETCH_TRAINING_CALENDAR_REQUEST,
                    types.FETCH_TRAINING_CALENDAR_SUCCESS,
                    types.FETCH_TRAINING_CALENDAR_FAIL,
                ],
                payload: {
                    request: {
                        url: `${endpoints.personalTraining.single}/${current.id}/calendar`,
                        headers: { ...getAuthorizationBearerHeader() },
                    },
                },
                meta: {
                    entity: PERSONAL_TRAINING_CALENDAR,
                    ...action.meta,
                },
            });
            break;

        case types.FETCH_TRAINING_CALENDAR_SUCCESS:
            dispatch(setTrainingCalendar({ days: payload.data }));
            break;

        case types.GET_TRAINING_DAY:
            dispatch(clearAllAlerts());
            dispatch({
                types: [
                    types.FETCH_TRAINING_DAY_REQUEST,
                    types.FETCH_TRAINING_DAY_SUCCESS,
                    types.FETCH_TRAINING_DAY_FAIL,
                ],
                payload: {
                    request: {
                        url: `${endpoints.personalTraining.single}/${
                            current.id
                        }?expand=days&date=${getFormattedDate(current.date)}`,
                        headers: { ...getAuthorizationBearerHeader() },
                    },
                },
                meta: {
                    entity: PERSONAL_TRAINING_DAY,
                    ...action.meta,
                },
            });
            break;

        case types.FETCH_TRAINING_DAY_SUCCESS:
            daysObj = {
                id: payload.data.id,
                days: {
                    ...arrayToObject(payload.data.days, 'date'),
                },
            };

            dispatch(setTrainingDay(daysObj));

            if (payload.data.days[0].exercises.length > 0) {
                playerObj = {
                    listType: initialState.current.listType,
                    playlistMovieIndex: 0,
                };
                dispatch(setCurrentMovie(playerObj));
            }
            break;

        case types.GET_TRAINING_LIST:
            dispatch(clearAllAlerts());
            dispatch({
                types: [
                    types.FETCH_TRAINING_LIST_REQUEST,
                    types.FETCH_TRAINING_LIST_SUCCESS,
                    types.FETCH_TRAINING_LIST_FAIL,
                ],
                payload: {
                    request: {
                        url: payload.url,
                        headers: { ...getAuthorizationBearerHeader() },
                    },
                },
                meta: {
                    entity: PERSONAL_TRAINING_LIST,
                    ...action.meta,
                },
            });
            break;

        case types.FETCH_TRAINING_LIST_SUCCESS:
            dispatch(setKnownTraining({ ...arrayToObject(payload.data.items, 'id') }));
            dispatch(
                setTrainingList({
                    ids: arrayToArrayOfKeys(payload.data.items, 'id'),
                    pagination: {
                        nextPageToFetch: trainingsList.pagination.nextPageToFetch + 1,
                        ...payload.data.pagination,
                    },
                })
            );
            break;

        case types.FETCH_TRAINING_CALENDAR_FAIL:
        case types.FETCH_SINGLE_TRAINING_FAIL:
        case types.FETCH_TRAINING_DAY_FAIL:
            error = errorHandler(action.error);
            error.map((err) => dispatch(setAlert(err, PERSONAL_TRAINING)));
            break;
        // no default
    }
};

export default personalTrainingMiddleware;
