import axios from 'axios';

import { config } from '../config';
import { getAuthorizationBearerHeader } from '../utills/get-authorization-bearer-header';

export const setNewsletter = (data) => {
    return axios.post(config.endpoints.newsletterSignUp, data, {
        headers: { ...getAuthorizationBearerHeader() },
    });
};

export const deleteNewsletter = (data) => {
    return axios.post(
        config.endpoints.newsletterUnsubscribe,
        { email: data.email },
        {
            headers: { ...getAuthorizationBearerHeader() },
        }
    );
};
