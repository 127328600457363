// extracted by mini-css-extract-plugin
export var cartEmpty = "shop-order-summary-module--cart-empty--N431E";
export var checkbox = "shop-order-summary-module--checkbox--UETqy";
export var consentForm = "shop-order-summary-module--consent-form--dNewA";
export var consentFormDisclaimer = "shop-order-summary-module--consent-form-disclaimer--Q-62E";
export var content = "shop-order-summary-module--content--vh0W7";
export var description = "shop-order-summary-module--description--Y3g7e";
export var main = "shop-order-summary-module--main--DXarG";
export var orderBox = "shop-order-summary-module--order-box--upIRL";
export var paymentBox = "shop-order-summary-module--payment-box--jlPUu";
export var paymentPreview = "shop-order-summary-module--payment-preview--nqFTJ";
export var paymentSpace = "shop-order-summary-module--payment-space--hp6Mu";
export var paymentTitle = "shop-order-summary-module--payment-title--jCjnn";
export var registerForm = "shop-order-summary-module--register-form--wf1KZ";
export var registerFormBox = "shop-order-summary-module--register-form-box--Xc0ma";
export var span = "shop-order-summary-module--span--o3Jsd";
export var submitButton = "shop-order-summary-module--submit-button--1kOD3";
export var summary = "shop-order-summary-module--summary--xEvQA";
export var summaryBox = "shop-order-summary-module--summary-box--1+YhH";
export var title = "shop-order-summary-module--title--DC28b";