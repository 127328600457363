import axiosInstance from './axiosInstance';

import { config } from '../config';
import { getAuthorizationBearerHeader } from '../utills/get-authorization-bearer-header';

export const getPlannerTemplatesAuth = () => {
    return axiosInstance.default.client({
        url: config.endpoints.mealPlanner.templateAuth,
        headers: { ...getAuthorizationBearerHeader() },
    });
};
