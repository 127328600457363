import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import {
    button,
    circle,
    fullButton,
    normalButton,
    text,
    transitionButton,
    iconBox,
    icon,
    disabledLike,
} from './cart-button.module.scss';
import CartIcon from '../../assets/images/svg/icon-shopping-cart.svg';

export const CartButton = ({
    className,
    title,
    transitions,
    fullSize,
    disabled,
    onClick,
    disabledStyle,
    ...rest
}) => {
    const ref = useRef(null);

    const handleClick = () => {
        ref.current.blur();
        onClick();
    };

    return (
        <button
            className={`
                ${button} 
                ${transitions ? transitionButton : normalButton} 
                ${fullSize ? fullButton : circle}
                ${className}
                ${disabledStyle ? disabledLike : ''}
            `}
            ref={ref}
            onClick={handleClick}
            disabled={disabled}
            {...rest}
        >
            <div className={iconBox}>
                <CartIcon className={icon} />
            </div>
            <div className={text}>{title}</div>
        </button>
    );
};

CartButton.propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
    transitions: PropTypes.bool,
    fullSize: PropTypes.bool,
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
};

CartButton.defaultProps = {
    className: '',
    title: 'Dodaj do koszyka',
    transitions: true,
    fullSize: false,
    disabled: false,
    onClick: () => {},
};

export default CartButton;
