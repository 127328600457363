import {
    types,
    NOTIFICATIONS,
    setNotificationsAction,
    getNotificationsCountAction,
    setNotificationsIntervalId,
    setNotificationsCountAction,
    markNotificationsAsSeen,
    restoreNotificationsInitialItemsAction,
    restoreNotificationsInitialStateAction,
    setNewNotificationsAvailableAction,
    clearNotificationsAction,
} from './notifications.actions';
import { config } from '../../config';
import { getCurrentProfileId } from '../profile/profile.selectors';
import {
    selectNotificationsIntervalId,
    selectNotificationsItems,
    selectNotReadNotificationsIds,
    selectNotSeenNotificationsIds,
} from './notifications.selectors';
import { statusIdle } from '../ui/ui.actions';
import { getAbsolutePath } from '../../routes';
import { types as userTypes } from '../user/user.actions';
import { getAuthorizationBearerHeader } from '../../utills/get-authorization-bearer-header';

const { CLEAR_USER_DATA } = userTypes;

const notificationsMiddleware = (state) => (next) => (action) => {
    const { dispatch, getState } = state;
    const { type, payload } = action;
    next(action);

    switch (type) {
        case types.GET_NOTIFICATIONS_COUNT_INTERVAL:
            dispatch(getNotificationsCountAction());
            const newInterval = setInterval(() => {
                dispatch(getNotificationsCountAction());
            }, 60000);
            dispatch(setNotificationsIntervalId(newInterval));
            break;

        case types.GET_NOTIFICATIONS:
            const fetchConfig = {
                types: [
                    types.FETCH_NOTIFICATIONS_REQUEST,
                    types.FETCH_NOTIFICATIONS_SUCCESS,
                    types.FETCH_NOTIFICATIONS_FAIL,
                ],
                payload: {
                    request: {
                        url: `${config.endpoints.notifications.page(
                            payload.page,
                            payload.perPage,
                            getCurrentProfileId(getState())
                        )}`,
                        headers: { ...getAuthorizationBearerHeader() },
                    },
                },
                meta: {
                    entity: NOTIFICATIONS,
                },
            };
            dispatch(fetchConfig);
            break;

        case types.FETCH_NOTIFICATIONS_SUCCESS:
            const actualItemsIds = selectNotificationsItems(getState()).map(
                (item) => item.messageId
            );
            dispatch(
                setNotificationsAction({
                    ...payload.data,
                    items: payload.data.items.filter(
                        (item) => !actualItemsIds.includes(item.messageId)
                    ),
                })
            );
            dispatch(markNotificationsAsSeen());
            break;

        case types.GET_NOTIFICATIONS_COUNT:
            const currentProfileId = getCurrentProfileId(getState());
            const countFetchConfig = {
                types: [
                    types.FETCH_NOTIFICATIONS_COUNT_REQUEST,
                    types.FETCH_NOTIFICATIONS_COUNT_SUCCESS,
                    types.FETCH_NOTIFICATIONS_COUNT_FAIL,
                ],
                payload: {
                    request: {
                        url: config.endpoints.notifications.notSeenCount(currentProfileId),
                        headers: { ...getAuthorizationBearerHeader() },
                    },
                },
            };
            if (currentProfileId) {
                dispatch(countFetchConfig);
            }
            break;

        case types.FETCH_NOTIFICATIONS_COUNT_SUCCESS:
            const actualCount = getState().notifications.notSeenCount;
            const newCount = Number(payload.data.count);
            if (actualCount !== newCount) {
                dispatch(setNotificationsCountAction(newCount));
            }
            if (
                actualCount < newCount &&
                window.location.pathname === getAbsolutePath('APP_MY_ACCOUNT_NOTIFICATIONS')
            ) {
                dispatch(setNewNotificationsAvailableAction());
            }
            break;

        case types.MARK_NOTIFICATIONS_AS_SEEN:
            const notSeenIds = selectNotSeenNotificationsIds(getState());
            const markAsSeenConfig = {
                types: [
                    types.SEND_NOTIFICATIONS_SEEN_REQUEST,
                    types.SEND_NOTIFICATIONS_SEEN_SUCCESS,
                    types.SEND_NOTIFICATIONS_SEEN_FAIL,
                ],
                payload: {
                    request: {
                        method: 'post',
                        url: config.endpoints.notifications.markAsSeen(
                            getCurrentProfileId(getState())
                        ),
                        headers: { ...getAuthorizationBearerHeader() },
                        data: {
                            messageIds: notSeenIds,
                        },
                    },
                },
            };
            if (notSeenIds.length) {
                dispatch(markAsSeenConfig);
            }
            break;

        case types.SEND_NOTIFICATIONS_SEEN_SUCCESS:
            dispatch(getNotificationsCountAction());
            break;

        case types.MARK_NOTIFICATIONS_AS_READ:
            const notReadIds = selectNotReadNotificationsIds(getState());
            const markAsReadConfig = {
                types: [
                    types.SEND_NOTIFICATIONS_READ_REQUEST,
                    types.SEND_NOTIFICATIONS_READ_SUCCESS,
                    types.SEND_NOTIFICATIONS_READ_FAIL,
                ],
                payload: {
                    request: {
                        method: 'post',
                        url: config.endpoints.notifications.markAsRead(
                            getCurrentProfileId(getState())
                        ),
                        headers: { ...getAuthorizationBearerHeader() },
                        data: {
                            messageIds: notReadIds,
                        },
                    },
                },
            };
            if (notReadIds.length) {
                dispatch(markAsReadConfig);
            }
            break;

        case types.CLEAR_NOTIFICATIONS_ITEMS:
            dispatch(statusIdle(NOTIFICATIONS));
            dispatch(restoreNotificationsInitialItemsAction());
            break;

        case types.CLEAR_NOTIFICATIONS:
            const intervalToClear = selectNotificationsIntervalId(getState());
            if (intervalToClear) {
                clearInterval(intervalToClear);
            }
            dispatch(statusIdle(NOTIFICATIONS));
            dispatch(restoreNotificationsInitialStateAction());
            break;

        case CLEAR_USER_DATA:
            dispatch(clearNotificationsAction());
            break;

        default:
            break;
    }
};

export default notificationsMiddleware;
