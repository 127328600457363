import { selectLoaderByEntity } from '../../ui/ui.selectors';

const selectDiscountCode = (state) => state.shop.order.discountCode;

const selectChosenXselling = (state) => state.shop.order.chosenXselling;

const selectPayByPointsItem = (itemId) => (state) => {
    return state.shop.order.payByPoints?.items[itemId] || {};
};

const selectPayByPointsBalance = (state) => {
    return state.shop.order.payByPoints?.balance;
};

const selectPayByPointsItems = (state) => state.shop.order.payByPoints.items;

const selectOrderIsLoading = (state) => selectLoaderByEntity(state, 'order');

export {
    selectDiscountCode,
    selectChosenXselling,
    selectPayByPointsItem,
    selectPayByPointsBalance,
    selectPayByPointsItems,
    selectOrderIsLoading,
};
