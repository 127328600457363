import { statusIdle } from '../ui/ui.actions';
import {
    getSubscriptionsAction,
    restoreInitialSubscriptionsStateAction,
    setSubscriptionsAction,
    SUBSCRIPTIONS,
    types,
} from './subscriptions.actions';
import { config } from '../../config';
import { selectSubscriptionsPagination } from './subscriptions.selectors';
import { getAuthorizationBearerHeader } from '../../utills/get-authorization-bearer-header';

const {
    GET_SUBSCRIPTIONS,
    RELOAD_SUBSCRIPTIONS,
    FETCH_SUBSCRIPTIONS_REQUEST,
    FETCH_SUBSCRIPTIONS_SUCCESS,
    FETCH_SUBSCRIPTIONS_FAIL,
    CLEAR_SUBSCRIPTIONS,
} = types;

const DEFAULT_PAGE_ON_RELOAD = 1;

const subscriptionsMiddleware = (state) => (next) => (action) => {
    const { dispatch, getState } = state;
    const { type, payload } = action;
    const pagination = selectSubscriptionsPagination(getState());
    next(action);

    switch (type) {
        case GET_SUBSCRIPTIONS:
            dispatch({
                types: [
                    FETCH_SUBSCRIPTIONS_REQUEST,
                    FETCH_SUBSCRIPTIONS_SUCCESS,
                    FETCH_SUBSCRIPTIONS_FAIL,
                ],
                payload: {
                    request: {
                        url: config.endpoints.subscriptions.page(
                            payload?.page || pagination.currentPage + 1,
                            payload?.perPage || pagination.perPage
                        ),
                        headers: { ...getAuthorizationBearerHeader() },
                    },
                },
                meta: { entity: SUBSCRIPTIONS },
            });
            break;

        case FETCH_SUBSCRIPTIONS_SUCCESS:
            dispatch(setSubscriptionsAction(payload.data));
            break;

        case RELOAD_SUBSCRIPTIONS:
            dispatch(getSubscriptionsAction(DEFAULT_PAGE_ON_RELOAD));
            break;

        case CLEAR_SUBSCRIPTIONS:
            dispatch(statusIdle(SUBSCRIPTIONS));
            dispatch(restoreInitialSubscriptionsStateAction());
            break;

        default:
            break;
    }
};

export default subscriptionsMiddleware;
