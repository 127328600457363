import React from 'react';

import {
    name,
    container,
    green,
    large,
    priceRow,
    strike,
    summary,
    value,
} from './shop-step-order-summary.module.scss';
import { ICartItem, ISubscriptionSp } from '../../models/cart.model';
import { config } from '../../config';
import Separator from '../atoms/separator';

interface IShopStepSummaryProps {
    className?: string;
    cartItems: ICartItem[];
}

// 😭
const BASE_STANDARD_INDIVIDUAL_PRICE_GROSS_INTEGER = Number(
    process.env.BASE_STANDARD_INDIVIDUAL_PRICE_GROSS_INTEGER
);
const BASE_PREMIUM_INDIVIDUAL_PRICE_GROSS_INTEGER = Number(
    process.env.BASE_PREMIUM_INDIVIDUAL_PRICE_GROSS_INTEGER
);
const BASE_PREMIUM_PLUS_INDIVIDUAL_PRICE_GROSS_INTEGER = Number(
    process.env.BASE_PREMIUM_PLUS_INDIVIDUAL_PRICE_GROSS_INTEGER
);
const BASE_STANDARD_COUPLE_PRICE_GROSS_INTEGER = Number(
    process.env.BASE_STANDARD_COUPLE_PRICE_GROSS_INTEGER
);
const BASE_PREMIUM_COUPLE_PRICE_GROSS_INTEGER = Number(
    process.env.BASE_PREMIUM_COUPLE_PRICE_GROSS_INTEGER
);
const BASE_PREMIUM_PLUS_COUPLE_PRICE_GROSS_INTEGER = Number(
    process.env.BASE_PREMIUM_PLUS_COUPLE_PRICE_GROSS_INTEGER
);

const ShopStepOrderSummary: React.FC<IShopStepSummaryProps> = ({ className = '', cartItems }) => {
    const installmentsItems = cartItems.filter(
        (item) =>
            item.product.paymentModel === config.paymentModelsMap.installments.id ||
            item.product.paymentModel === config.paymentModelsMap.renewableInstallments.id
    );
    const cartBasePriceInteger = cartItems.reduce((acc, item) => {
        if (item.subscriptionSp) {
            return acc + getSubscriptionSpMaxPriceInteger(item.subscriptionSp);
        }
        return acc + item.basePriceTotal.grossInteger;
    }, 0);
    const cartPriceInteger = cartItems.reduce((acc, item) => {
        if (item.subscriptionSp) {
            return (
                acc + item.subscriptionSp.price.grossInteger * item.subscriptionSp.installmentCount
            );
        }
        return acc + item.priceTotal.grossInteger;
    }, 0);
    const cartDiscountInteger = cartBasePriceInteger - cartPriceInteger;
    const nowToPayInteger = cartItems.reduce((acc, item) => {
        if (item.subscriptionSp) {
            return acc + item.subscriptionSp.price.grossInteger;
        }
        return acc + item.priceTotal.grossInteger;
    }, 0);
    const monthlyToPayInteger = installmentsItems.reduce((acc, item) => {
        if (item.subscriptionSp) {
            if (item.subscriptionSp.installmentCount > 1) {
                return acc + item.subscriptionSp.price.grossInteger;
            }
            return acc;
        }
        return acc + item.priceTotal.grossInteger;
    }, 0);

    return (
        <div className={`${container} ${className}`}>
            <p className={name}>Łączna wartość zamówienia:</p>
            {cartDiscountInteger > 0 ? (
                installmentsItems.length > 0 && monthlyToPayInteger > 0 ? (
                    <>
                        <div className={priceRow}>
                            <p>Przed zniżką:</p>
                            <p className={strike}>{cartBasePriceInteger / 100} zł</p>
                        </div>
                        <div className={priceRow}>
                            <p>Po zniżce:</p>
                            <p className={`${value} ${green}`}>{cartPriceInteger / 100} zł</p>
                        </div>
                        <div className={priceRow}>
                            <p>Do zapłaty teraz:</p>
                            <p className={`${value} ${green} ${large}`}>
                                {nowToPayInteger / 100} zł
                            </p>
                        </div>
                    </>
                ) : (
                    <>
                        <div className={priceRow}>
                            <p>Przed zniżką:</p>
                            <p className={strike}>{cartBasePriceInteger / 100} zł</p>
                        </div>
                        <div className={priceRow}>
                            <p>Do zapłaty po zniżce:</p>
                            <p className={`${value} ${green} ${large}`}>
                                {cartPriceInteger / 100} zł
                            </p>
                        </div>
                    </>
                )
            ) : installmentsItems.length > 0 && monthlyToPayInteger > 0 ? (
                <>
                    <div className={priceRow}>
                        <p>Do zapłaty teraz:</p>
                        <p className={`${value} ${green} ${large}`}>{nowToPayInteger / 100} zł</p>
                    </div>
                </>
            ) : (
                <>
                    <div className={priceRow}>
                        <p>Do zapłaty:</p>
                        <p className={`${value} ${large}`}>{cartPriceInteger / 100} zł</p>
                    </div>
                </>
            )}
        </div>
    );
};

function getSubscriptionSpMaxPriceInteger(subscriptionSp: ISubscriptionSp) {
    let basePriceGrossInteger;
    if (subscriptionSp.isSingle) {
        if (subscriptionSp.isStandard) {
            basePriceGrossInteger = BASE_STANDARD_INDIVIDUAL_PRICE_GROSS_INTEGER;
        } else if (subscriptionSp.isPremium) {
            basePriceGrossInteger = BASE_PREMIUM_INDIVIDUAL_PRICE_GROSS_INTEGER;
        } else {
            basePriceGrossInteger = BASE_PREMIUM_PLUS_INDIVIDUAL_PRICE_GROSS_INTEGER;
        }
    } else {
        if (subscriptionSp.isStandard) {
            basePriceGrossInteger = BASE_STANDARD_COUPLE_PRICE_GROSS_INTEGER;
        } else if (subscriptionSp.isPremium) {
            basePriceGrossInteger = BASE_PREMIUM_COUPLE_PRICE_GROSS_INTEGER;
        } else {
            basePriceGrossInteger = BASE_PREMIUM_PLUS_COUPLE_PRICE_GROSS_INTEGER;
        }
    }
    return basePriceGrossInteger * subscriptionSp.monthsCount;
}

export default ShopStepOrderSummary;
