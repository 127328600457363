import axios from 'axios';

import { config } from '../config';
import { getAuthorizationBearerHeader } from '../utills/get-authorization-bearer-header';

export const getAllRecipes = (page, pageSize, profileId, searchTerm) => {
    return axios.get(config.endpoints.recipes.all.getPage(page, pageSize, profileId, searchTerm), {
        headers: { ...getAuthorizationBearerHeader() },
    });
};

export const getPublicRecipes = (page, pageSize, searchTerm) => {
    return axios.get(config.endpoints.recipes.public.getPage(page, pageSize, searchTerm), {
        headers: { ...getAuthorizationBearerHeader() },
    });
};

export const getYourRecipes = (page, pageSize, profileId, searchTerm) => {
    return axios.get(config.endpoints.recipes.your.getPage(page, pageSize, profileId, searchTerm), {
        headers: { ...getAuthorizationBearerHeader() },
    });
};

export const getFavoriteRecipes = (page, pageSize, profileId, searchTerm) => {
    return axios.get(
        config.endpoints.recipes.favorite.getPage(page, pageSize, profileId, searchTerm),
        { headers: { ...getAuthorizationBearerHeader() } }
    );
};

export const getSingleRecipe = (id) => {
    return axios.get(config.endpoints.recipes.single.get(id), {
        headers: { ...getAuthorizationBearerHeader() },
    });
};

export const createSingleRecipe = (data) => {
    return axios.post(config.endpoints.recipes.single.create, data, {
        headers: { ...getAuthorizationBearerHeader() },
    });
};

export const editSingleRecipe = (id, data) => {
    return axios.put(config.endpoints.recipes.single.edit(id), data, {
        headers: { ...getAuthorizationBearerHeader() },
    });
};

export const deleteSingleRecipe = (id) => {
    return axios.delete(config.endpoints.recipes.single.delete(id), {
        headers: { ...getAuthorizationBearerHeader() },
    });
};

export const addRecipeToFavorites = (recipeId, profileId) => {
    return axios.post(
        config.endpoints.recipes.single.editIsFavorite(recipeId, profileId),
        {},
        { headers: { ...getAuthorizationBearerHeader() } }
    );
};

export const removeRecipeFromFavorites = (recipeId, profileId) => {
    return axios.delete(config.endpoints.recipes.single.editIsFavorite(recipeId, profileId), {
        headers: { ...getAuthorizationBearerHeader() },
    });
};

export const getRecipesIngredients = () => {
    return axios.get(config.endpoints.recipes.ingredients.get, {
        headers: { ...getAuthorizationBearerHeader() },
    });
};

export const getRecipesUnits = () => {
    return axios.get(config.endpoints.recipes.units.get, {
        headers: { ...getAuthorizationBearerHeader() },
    });
};

export const rateSingleRecipe = (id, profileId, data) => {
    return axios.post(config.endpoints.recipes.single.rate(id, profileId), data, {
        headers: { ...getAuthorizationBearerHeader() },
    });
};

export const commentSingleRecipe = (id, profileId, data) => {
    return axios.post(config.endpoints.recipes.single.comment(id, profileId), data, {
        headers: { ...getAuthorizationBearerHeader() },
    });
};

export const saveToPdfSingleRecipe = (id, data) => {
    return axios.post(config.endpoints.recipes.single.pdf(id), data, {
        headers: { ...getAuthorizationBearerHeader() },
        responseType: 'blob',
    });
};

export const changeSingleRecipeIngredientToProfile = (recipeId, profileId, data) => {
    return axios.post(
        config.endpoints.recipes.single.changeIngredientToProfile(recipeId, profileId),
        data,
        { headers: { ...getAuthorizationBearerHeader() } }
    );
};
