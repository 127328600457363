import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'gatsby';

import {
    title,
    basePricing,
    bigCart,
    pricing,
    priceDisclaimer,
    cartListItem,
    count,
    dropdownItemLayout,
    counterButton,
    discountBenefits,
    discountBenefitsBadge,
    discountConditionLabel,
    discountConditions,
    imgWrapper,
    number,
    thrash,
    reverse,
    priceText,
    ratioClass,
    pointsWrapper,
    animatedKeton,
    pointsCheckbox,
} from './cart-list-item.module.scss';
import TrashCan from '../../../assets/images/svg/icon-trash.svg';
import { config } from '../../../config';
import { product } from '../../../global-enums/product';
import { IPrice } from '../../../models/price.model';
import { ICartItem, ISubscriptionSp } from '../../../models/cart.model';
import { getCartItemDiscount, getPrice, getVariantQuery } from '../../../utills/cart-item';
import { delSingleProduct } from '../../../redux/shop/cart/cart.actions';
import { selectUserData } from '../../../redux/user/user.selectors';
import useMedia from '../../../hooks/use-media';
import { getSubscriptionDurationLabel } from '../../../utills/get-subscription-duration-label';

import PriceFragment from '../../atoms/price-fragment';
import DiscountConditionList from '../discount-condition-list';
import DiscountsDropdown from '../discounts-dropdown';
import Counter from '../../atoms/counter';
import DiscountBenefitList from '../discount-benefit-list';
import RatioImage from '../../atoms/ratio-image';
import AnimatedKeton from '../../atoms/animated-keton';
import PointsPaymentCheckbox from '../points-payment-checkbox';
import Markdown from '../../hoc/markdown';

const { mediaRelationsMap } = config;

export type TCartItemContext = 'cart' | 'cart-dropdown' | 'order-summary' | 'order-history';

export interface ICartListItemProps {
    item: ICartItem;
    className?: string;
    readOnly?: boolean;
    context: TCartItemContext;
    noSubscriptionPrice?: boolean;
}

export default function CartListItem({
    item,
    className = '',
    readOnly = false,
    context, // cart, cart-dropdown, order-summary, order-history
    noSubscriptionPrice = false,
}: ICartListItemProps) {
    const { variantId, subscriptionSp, variant, quantity, itemId } = item;
    const { media, slug = '', id, name = '', kind } = item.product;

    const isUserLogged = useSelector(selectUserData) !== null;
    const dispatch = useDispatch();
    const { url: imageUrl, alt: imageAlt } = useMedia({
        media,
        relation: mediaRelationsMap.listCover,
    });

    const {
        isDiscounted,
        conditionLabels,
        benefitLabels,
        color: discountColor,
        labelPointsBenefit,
    } = getCartItemDiscount(item);
    const prices = getPrice(item);

    const handleDeleteProduct = () => {
        dispatch(delSingleProduct({ productId: id, variantId }, { cartItemId: itemId }));
    };

    return (
        <div
            className={getCartListItemClasses({
                isDropdownItem: context === 'cart-dropdown',
                className,
            })}
        >
            {!readOnly && (
                <button type={'button'} className={thrash} onClick={handleDeleteProduct}>
                    <TrashCan />
                </button>
            )}
            <Link to={`/sklep/${slug}`} className={imgWrapper}>
                <RatioImage src={imageUrl} alt={imageAlt} ratioClass={ratioClass} />
            </Link>
            {!variant && (
                <DiscountConditionList
                    conditions={conditionLabels}
                    className={discountConditions}
                    labelClassName={discountConditionLabel}
                    limit={1}
                    color={discountColor}
                    DropdownComponent={
                        <DiscountsDropdown conditions={conditionLabels} benefits={benefitLabels} />
                    }
                />
            )}
            <Link to={`/sklep/${slug}${getVariantQuery(variant)}`} className={title}>
                {name}
                {variant?.attributesValues?.length > 0 && (
                    <div>
                        <Markdown>
                            {subscriptionSp
                                ? getSubscriptionAttributesCaption(
                                      subscriptionSp,
                                      item.basePriceTotal,
                                      context === 'cart-dropdown',
                                      noSubscriptionPrice
                                  )
                                : variant.attributesValues
                                      .map((attribute) => attribute.label)
                                      .join(' / ')}
                        </Markdown>
                    </div>
                )}
            </Link>
            {!variant && (
                <DiscountBenefitList
                    benefits={benefitLabels}
                    className={discountBenefits}
                    badgeClassName={discountBenefitsBadge}
                    limit={1}
                    color={discountColor}
                />
            )}
            <div className={pricing}>
                {subscriptionSp && subscriptionSp.isMultiPayment && (
                    <span>
                        {subscriptionSp.installmentCount}
                        &nbsp;x&nbsp;
                    </span>
                )}
                <PriceFragment
                    basePrice={prices.basePrice}
                    price={prices.price}
                    isDiscounted={isDiscounted}
                    basePriceClassName={basePricing}
                    priceClassName={priceText}
                    color={discountColor}
                />
            </div>
            <div className={count}>
                {kind === product.normal && !readOnly && (
                    <Counter
                        initialValue={quantity}
                        min={0}
                        className={counterButton}
                        numberStyle={number}
                    />
                )}
            </div>
            {isUserLogged && labelPointsBenefit && Object.keys(labelPointsBenefit).length > 0 && (
                <div className={pointsWrapper}>
                    <AnimatedKeton className={animatedKeton} />
                    <PointsPaymentCheckbox
                        id={itemId}
                        productId={id}
                        className={pointsCheckbox}
                        labelPointsBenefit={labelPointsBenefit}
                        context={context}
                    />
                </div>
            )}
        </div>
    );
}

function getSubscriptionAttributesCaption(
    subscriptionSp: ISubscriptionSp,
    itemBasePrice: IPrice,
    isShort: boolean,
    noSubscriptionPrice: boolean
) {
    const payment = `${isShort ? '' : 'Płatność '}${
        subscriptionSp.isSinglePayment ? 'Jednorazowa' : 'Miesięczna'
    }`;
    const level = `${isShort ? '' : 'Pakiet '}${
        subscriptionSp.isLevelUp ? 'Premium+' : subscriptionSp.isPremium ? 'Premium' : 'Standard'
    }`;
    const duration = `${isShort ? '' : 'Czas trwania '}${getSubscriptionDurationLabel(
        subscriptionSp.monthsCount
    )}`;
    const account = `${isShort ? '' : 'Typ konta '}${
        subscriptionSp.isPair ? 'Dla par' : 'Indywidualne'
    }`;
    const price =
        (isShort && subscriptionSp.isMultiPayment) || noSubscriptionPrice
            ? ''
            : `Całkowita wartość zamówienia ${itemBasePrice.grossDisplay}`;
    return `${payment} / ${level} / ${duration} / ${account} ${
        price ? `<br><br><strong>${price}</strong>` : ''
    }`;
}

interface IGetCartListItemClasses {
    isDropdownItem: boolean;
    className: string;
}

const getCartListItemClasses = ({ isDropdownItem, className }: IGetCartListItemClasses) => {
    const layoutClassName = isDropdownItem ? dropdownItemLayout : bigCart;

    return `${cartListItem} ${layoutClassName} ${className}`;
};
