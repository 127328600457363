import { config } from '../../config';

import {
    SURVEY,
    types,
    setSurveyValuesAction,
    restoreInitialStateAction,
    setSurveyStepAction,
} from './survey.actions';
import { statusIdle } from '../ui/ui.actions';
import { getCurrentProfileId } from '../profile/profile.selectors';
import { formatFormikErrors } from '../../communication/utils';
import { selectSurvey } from './survey.selectors';
import { getAuthorizationBearerHeader } from '../../utills/get-authorization-bearer-header';

const {
    GET_SURVEY,
    CREATE_SURVEY,
    VALIDATE_SURVEY,
    FETCH_SURVEY_REQUEST,
    FETCH_SURVEY_SUCCESS,
    FETCH_SURVEY_FAIL,
    POST_SURVEY_REQUEST,
    POST_SURVEY_SUCCESS,
    POST_SURVEY_FAIL,
    VALIDATE_SURVEY_REQUEST,
    VALIDATE_SURVEY_SUCCESS,
    VALIDATE_SURVEY_FAIL,
    CLEAR_SURVEY,
} = types;

const { formsStatusMap } = config;

const surveyMiddleware = (state) => (next) => (action) => {
    const { dispatch, getState } = state;
    const { type, payload, meta } = action;
    const { step } = selectSurvey(getState());
    const formikBag = meta?.formikBag || meta?.previousAction?.meta?.formikBag;
    next(action);

    switch (type) {
        case GET_SURVEY:
            const fetchSurvey = {
                types: [FETCH_SURVEY_REQUEST, FETCH_SURVEY_SUCCESS, FETCH_SURVEY_FAIL],
                payload: {
                    request: {
                        url: config.endpoints.survey.getSingle(
                            getCurrentProfileId(getState()),
                            payload
                        ),
                        headers: { ...getAuthorizationBearerHeader() },
                    },
                },
                meta: { entity: SURVEY },
            };
            dispatch(fetchSurvey);
            break;

        case FETCH_SURVEY_SUCCESS:
            dispatch(setSurveyValuesAction(payload.data));
            break;

        case CREATE_SURVEY:
            if (formikBag) {
                formikBag.setStatus(formsStatusMap.loading);
            }
            dispatch(setSurveyStepAction(4));
            const postSurvey = {
                types: [POST_SURVEY_REQUEST, POST_SURVEY_SUCCESS, POST_SURVEY_FAIL],
                payload: {
                    request: {
                        method: 'POST',
                        url: config.endpoints.survey.post(getCurrentProfileId(getState())),
                        data: payload,
                        headers: { ...getAuthorizationBearerHeader() },
                    },
                },
                meta: { ...meta },
            };
            dispatch(postSurvey);
            break;

        case POST_SURVEY_SUCCESS:
            if (formikBag) {
                formikBag.setStatus(formsStatusMap.success);
            }
            dispatch(setSurveyStepAction(5));
            break;

        case POST_SURVEY_FAIL:
            if (formikBag) {
                formikBag.setStatus(formsStatusMap.fail);
                formikBag.setErrors(formatFormikErrors(action.error));
            }
            dispatch(setSurveyStepAction(3));
            break;

        case VALIDATE_SURVEY:
            if (formikBag) {
                formikBag.setStatus(formsStatusMap.loading);
            }
            const validateSurvey = {
                types: [VALIDATE_SURVEY_REQUEST, VALIDATE_SURVEY_SUCCESS, VALIDATE_SURVEY_FAIL],
                payload: {
                    request: {
                        method: 'POST',
                        url: config.endpoints.survey.validate(getCurrentProfileId(getState())),
                        data: payload,
                        headers: { ...getAuthorizationBearerHeader() },
                    },
                },
                meta: { ...meta },
            };
            dispatch(validateSurvey);
            break;

        case VALIDATE_SURVEY_SUCCESS:
            dispatch(setSurveyStepAction(step + 1));
            if (formikBag) {
                formikBag.setStatus(formsStatusMap.idle);
                formikBag.setTouched({});
            }
            break;

        case VALIDATE_SURVEY_FAIL:
            if (formikBag) {
                formikBag.setStatus(formsStatusMap.fail);
                formikBag.setErrors(formatFormikErrors(action.error));
            }
            break;

        case CLEAR_SURVEY:
            dispatch(statusIdle(SURVEY));
            dispatch(restoreInitialStateAction());
            break;

        default:
            break;
    }
};

export default surveyMiddleware;
