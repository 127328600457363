import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import CartListItem from './cart-list-item';

export const CartList = ({
    readOnly = false,
    className = '',
    childItemClassname,
    items,
    context = '',
    noSubscriptionPrice = false,
}) => {
    const cartItems = context === 'cart-dropdown' ? _.take(items, 3) : items;

    return (
        <ul className={className}>
            {cartItems.map((item) => {
                return (
                    <li key={`card-list-item--${item.itemId}`}>
                        <CartListItem
                            item={item}
                            readOnly={readOnly}
                            className={childItemClassname}
                            context={context}
                            noSubscriptionPrice={noSubscriptionPrice}
                        />
                    </li>
                );
            })}
        </ul>
    );
};

export default CartList;

const mapStateToProps = ({ shop }) => {
    return {
        items: shop.cart && shop.cart.items ? shop.cart.items : [],
    };
};

export const CartListConnected = connect(mapStateToProps)(CartList);
