import axios from 'axios';

import { config } from '../config';
import { getAuthorizationBearerHeader } from '../utills/get-authorization-bearer-header';

export const getAllCourses = (perPage) =>
    axios.get(config.endpoints.allCourses(perPage), {
        headers: { ...getAuthorizationBearerHeader() },
    });

export const getMyCourses = (perPage) =>
    axios.get(config.endpoints.myCourses(perPage), {
        headers: { ...getAuthorizationBearerHeader() },
    });

export const getCourse = (id) =>
    axios.get(config.endpoints.chosenCourse(id), {
        headers: { ...getAuthorizationBearerHeader() },
    });
