import { config } from '../../config';

import { SURVEYS, types, setSurveysAction, restoreInitialStateAction } from './surveys.actions';
import { statusIdle } from '../ui/ui.actions';
import { getCurrentProfileId } from '../profile/profile.selectors';
import { getAuthorizationBearerHeader } from '../../utills/get-authorization-bearer-header';

const {
    GET_SURVEYS,
    FETCH_SURVEYS_REQUEST,
    FETCH_SURVEYS_SUCCESS,
    FETCH_SURVEYS_FAIL,
    CLEAR_SURVEYS,
} = types;

const surveysMiddleware = (state) => (next) => (action) => {
    const { dispatch, getState } = state;
    const { type, payload } = action;
    next(action);

    switch (type) {
        case GET_SURVEYS:
            const fetchSurveys = {
                types: [FETCH_SURVEYS_REQUEST, FETCH_SURVEYS_SUCCESS, FETCH_SURVEYS_FAIL],
                payload: {
                    request: {
                        url: config.endpoints.survey.page(
                            payload.page,
                            payload.perPage,
                            getCurrentProfileId(getState())
                        ),
                        headers: { ...getAuthorizationBearerHeader() },
                    },
                },
                meta: { entity: SURVEYS },
            };
            dispatch(fetchSurveys);
            break;

        case FETCH_SURVEYS_SUCCESS:
            dispatch(setSurveysAction(payload.data));
            break;

        case CLEAR_SURVEYS:
            dispatch(statusIdle(SURVEYS));
            dispatch(restoreInitialStateAction());
            break;

        default:
            break;
    }
};

export default surveysMiddleware;
